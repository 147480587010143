import Constant from 'src/constant'
import { DOCUMENT, Location } from '@angular/common'
import { environment } from '../../../environments/environment'
import { Inject, Injectable } from '@angular/core'
@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(@Inject(DOCUMENT) private document: Document,
    private _location: Location) { }

  isNull(val: any): boolean {
    return val === null || val === undefined || val === 'null'
  }

  isNotNull(val: any): boolean {
    return !this.isNull(val)
  }

  /**
   * Redirect to auth page
   * @param url 
   */
  redirectUrlToAuth(url: string) {
    let finalUrl = environment.APP_BASE_URL + url
    let redirectUrl = environment.AUTH_APP_BASE_URL + '?redirectUrl=' + finalUrl
    this.document.location.href = redirectUrl
  }

  /**
   * Redirect to logout page
   */
  logoutAuth() {
    this.document.location.href = environment.AUTH_APP_LOGOUT_URL + '?redirectUrl=' + environment.APP_BASE_URL
  }

  /**
   * Redirect to payment app if user done a payment
   * @param type 
   */
  redirectToPayment(type: string) {
    let redirectUrl = environment.APP_BASE_URL + Constant.ROUTERS.SUBSCRIPTION
    if (type === Constant.PLAN_TYPES.PLAN) {
      this.document.location.href = environment.PAYMENT_PURCHASE_PLAN + '?redirectUrl=' + redirectUrl
    } else if (type === Constant.PLAN_TYPES.SMS_BUY) {
      this.document.location.href = environment.PAYMENT_PURCHASE_SMS + '?redirectUrl=' + redirectUrl
    }
  }

  isFillArray(data: any[]) {
    if (Array.isArray(data) && data.length >= 1) {
      return true
    }
    return false
  }

  isNumber(num: any) {
    return !isNaN(num)
  }
  popPage() {
    this._location.back();
  }
}
