<mat-form-field style="width: 100%;">
  <mat-select [formControl]="countryCode" placeholder="Select Country Code">
    <mat-option>
      <ngx-mat-select-search
        [formControl]="countryCodeFilter"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let countryCode of filterCountryCode | async"
      [value]="countryCode"
    >
      {{ countryCode.name }} (+{{ countryCode.dialCode }})
    </mat-option>
  </mat-select>
</mat-form-field>
