import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-smartcard',
  templateUrl: './smartcard.component.html',
  styleUrls: ['./smartcard.component.scss']
})
export class SmartcardComponent implements OnInit {
  @Input() title: string
  @Input() count: number
  @Input() titleClass: string
  @Input() descIconClass: string


  constructor() { }

  ngOnInit(): void {
  }

}
