import { ApiService } from './api.service'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  constructor(private api: ApiService) { }

  getActiveSubscriptionAPI() {
    let url = this.api.getOptions('/payment/active-subscription')
    return this.api.get(url)
  }

  getSubscriberDetail() {
    let url = this.api.getOptions('/subscriber')
    return this.api.get(url)
  }
}
