<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 *ngIf="planEdit">Edit Plan</h1>
        <h1 *ngIf="!planEdit">Renew Plan</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item active">
            <a [routerLink]="" (click)="back()">
              Member Detail
            </a>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8 col-xl-8 col-md-8 col-sm-12 col-xs-12">
        <form [formGroup]="planDetailFormGroup">
          <ng-template matStepLabel>Plan Detail</ng-template>

          <div class="container-fluid">
            <div class="m-1rm align-center">
              <form-row *ngIf="!planEdit">
                <mat-form-field appearance="fill">
                  <mat-label>Select Plan</mat-label>
                  <mat-select formControlName="selectPlan" required>
                    <mat-option *ngFor="let plan of plans" [value]="plan">
                      {{plan.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form-row>

              <form-row *ngIf="planEdit">
                <mat-form-field>
                  <mat-label>Plan Name</mat-label>
                  <input
                    matInput
                    disabled
                    [value]="planDetailFormGroup.value.selectPlan.name"
                  />
                  <!-- formControlName="selectedPlanAmount" -->
                </mat-form-field>
              </form-row>
              <form-row>
                <mat-form-field>
                  <mat-label>Plan Amount</mat-label>
                  <input
                    matInput
                    disabled
                    [value]="planDetailFormGroup.value.selectPlan.price"
                  />
                  <!-- formControlName="selectedPlanAmount" -->
                </mat-form-field>
              </form-row>

              <form-row *ngIf="!planEdit">
                <mat-form-field>
                  <mat-label>Renew Date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="renewDate"
                    (focus)="renewDate.open()"
                    (click)="renewDate.open()"
                    formControlName="renewDate"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="renewDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #renewDate></mat-datepicker>
                </mat-form-field>
              </form-row>

              <form-row>
                <mat-form-field>
                  <mat-label>Paid Amount</mat-label>
                  <input
                    matInput
                    formControlName="paidAmount"
                    (keyup)="onChangePaidAmount()"
                    type="number"
                    required
                  />
                </mat-form-field>
              </form-row>

              <form-row>
                <mat-form-field>
                  <mat-label>Due Amount</mat-label>
                  <input matInput formControlName="dueAmount" />
                </mat-form-field>
              </form-row>

              <form-row>
                <mat-form-field>
                  <mat-label>Comments</mat-label>
                  <input
                    matInput
                    formControlName="comment"
                    placeholder="Comment eg. cash payment, check payment"
                  />
                </mat-form-field>
              </form-row>

              <form-row *ngIf="planEdit">
                <mat-form-field>
                  <mat-label>Plan Expiry</mat-label>
                  <input
                    matInput
                    [matDatepicker]="planExpiry"
                    (focus)="planExpiry.open()"
                    (click)="planExpiry.open()"
                    formControlName="planExpiry"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="planExpiry"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #planExpiry></mat-datepicker>
                </mat-form-field>
              </form-row>

              <form-row>
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="discountType"
                  (change)="onChangePaidAmount()"
                >
                  <span class="display-inline-block pb-2">
                    Discount Type
                  </span>
                  <br />
                  <mat-radio-button value="percent">Percent</mat-radio-button>
                  &nbsp; &nbsp;
                  <mat-radio-button value="amount">Amount</mat-radio-button>
                </mat-radio-group>
              </form-row>

              <form-row>
                <mat-form-field>
                  <mat-label>Discount</mat-label>
                  <input
                    matInput
                    placeholder="Discount"
                    formControlName="discount"
                    type="number"
                    (keyup)="onChangePaidAmount()"
                  />
                </mat-form-field>
              </form-row>
              <form-row>
                <mat-form-field>
                  <mat-label>Admission Fees</mat-label>
                  <input
                    matInput
                    placeholder="Addmission fees"
                    formControlName="admissionFees"
                    type="number"
                  />
                </mat-form-field>
              </form-row>
              <br />
              <div class="col-12">
                <button
                  mat-raised-button
                  color="primary"
                  [disabled]="!planDetailFormGroup.valid"
                  (click)="btnSavePlan()"
                >
                  Save
                  <mat-icon>save</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
