import { finalize } from 'rxjs/operators'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
  } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LoaderService implements HttpInterceptor {

  constructor(private spinner: NgxSpinnerService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    return next.handle(req).pipe(finalize(() => {
      this.spinner.hide();
    }))
  }
}
