<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Edit Member</h1>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row">
      <mat-horizontal-stepper [linear]="isLinear" #stepper style="width: 100%;">
        <mat-step [stepControl]="basicDetailFormGroup">
          <form [formGroup]="basicDetailFormGroup">
            <ng-template matStepLabel>Basic Detail</ng-template>
            <div class="container-fluid">
              <div class="m-1rm align-center">
                <form-row>
                  <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input
                      matInput
                      placeholder="Name"
                      formControlName="name"
                      required
                    />
                  </mat-form-field>
                </form-row>
                <br />
                <div>
                  <div class="row" style="padding: 0 7.5px;">
                    <div class="col-xs-12 col-md-3 col-lg-2">
                      <app-mobile-picker
                        selectCountycode="91"
                        (onCountryCodeSelected)="onCountryCodeSelected($event)"
                      ></app-mobile-picker>
                    </div>
                    <div class="col-xs-12 col-md-9 col-lg-4">
                      <mat-form-field class="full-width">
                        <input
                          type="number"
                          matInput
                          id="phone"
                          formControlName="mobile"
                          name="phone"
                          placeholder="Phone"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <br />
                <form-row>
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="trainingType"
                  >
                    <span class="pb-2 display-inline-block">
                      Training Type
                    </span>
                    <br />
                    <mat-radio-button value="General training"
                      >General Training</mat-radio-button
                    >
                    &nbsp; &nbsp;
                    <mat-radio-button value="Personal training"
                      >Personal Training</mat-radio-button
                    >
                  </mat-radio-group>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Membership Id</mat-label>
                    <input
                      matInput
                      placeholder="Membership Id"
                      formControlName="membershipId"
                      required
                      type="number"
                    />
                  </mat-form-field>
                </form-row>
                <form-row>
                  <mat-form-field appearance="fill">
                    <mat-label>Select Batch</mat-label>
                    <mat-select name="food" formControlName="selectBatch">
                      <mat-option *ngFor="let batch of batches" [value]="batch">
                        {{batch.batchName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </form-row>
                <br />
                <div class="col-12">
                  <button
                    mat-raised-button
                    color="primary"
                    matStepperNext
                    [disabled]="!basicDetailFormGroup.valid"
                  >
                    Next
                    <mat-icon>arrow_forward_ios</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="personalDetailFormGroup">
          <form [formGroup]="personalDetailFormGroup">
            <ng-template matStepLabel>Personal details</ng-template>
            <div class="container-fluid">
              <div class="m-1rm align-center">
                <form-row>
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="gender"
                  >
                    <span class="pb-2 display-inline-block">
                      Gender
                    </span>
                    <br />
                    <mat-radio-button value="male">Male</mat-radio-button>
                    &nbsp;&nbsp;
                    <mat-radio-button value="female">Female</mat-radio-button>
                  </mat-radio-group>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input
                      matInput
                      placeholder="Email"
                      formControlName="email"
                      type="email"
                    />
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Date of Birth</mat-label>
                    <input
                      matInput
                      (click)="dateOFBirth.open()"
                      (focus)="dateOFBirth.open()"
                      [matDatepicker]="dateOFBirth"
                      formControlName="dateOfBirth"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="dateOFBirth"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #dateOFBirth></mat-datepicker>
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Address</mat-label>
                    <input
                      matInput
                      placeholder="Address"
                      formControlName="address"
                    />
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Notes</mat-label>
                    <input
                      matInput
                      placeholder="Notes"
                      formControlName="notes"
                    />
                  </mat-form-field>
                </form-row>
                <br />
                <div class="col-12">
                  <button mat-raised-button matStepperPrevious color="primary">
                    <mat-icon>arrow_back_ios</mat-icon>Back
                  </button>
                  &nbsp; &nbsp;

                  <button
                    mat-raised-button
                    color="primary"
                    (click)="btnAddMember()"
                    [disabled]="!personalDetailFormGroup.valid"
                  >
                    Save
                    <mat-icon>save</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</section>
