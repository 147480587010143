<app-layout-header>
  <h1>Plan</h1>

  <div class="offset-4 col-sm-2" style="text-align: end;">
    <button (click)="addPlan()" class="btn btn-sm btn-success">
      &nbsp;
      <i class="fas fa-th-large"></i>
      &nbsp; Add Plan
    </button>
  </div>
</app-layout-header>

<div class="pt-3">
  <detail-card
    [allItems]="plans"
    (deleteCard)="deleteCard($event)"
    (editCard)="editCard($event)"
  ></detail-card>
</div>
