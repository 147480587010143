import { ApiService } from 'src/app/service/api/api.service'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(private api: ApiService) { }
  planUrl: string = '/subscriber/package'

  getAllPackage() {
    let url = this.api.getOptions(this.planUrl + 's')
    return this.api.get(url)
  }

  addPlan(plan) {
    let url = this.api.getOptions(this.planUrl, plan)
    return this.api.post(url)
  }

  editPlan(plan) {
    let url = this.api.getOptions(this.planUrl + '/' + plan._id, plan)
    return this.api.put(url)
  }

  deletePlan(plan) {
    let url = this.api.getOptions(this.planUrl + '/' + plan._id)
    return this.api.delete(url)
  }
}
