import { AuthService } from 'src/app/auth/auth.service'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class TokenService implements HttpInterceptor {
  private AUTH_HEADER = 'authToken';
  constructor(private auth: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }

    req = req.clone({
      headers: req.headers.set(this.AUTH_HEADER, this.auth.getToken())
    });
    return next.handle(req);
  }
}
