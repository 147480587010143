import Response from 'src/app/service/api/response-parser'
import { BatchDialogComponent } from './batch-dialog/batch-dialog.component'
import { BatchService } from './batch.service'
import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})
export class BatchComponent implements OnInit {

  batchs = []
  constructor(private dialog: MatDialog,
    private batchService: BatchService) { }

  ngOnInit(): void {
    this.getAllBatch()
  }

  async getAllBatch() {
    try {
      let allBatch = await this.batchService.getBatch()
      this.batchs = Response.parseBatch(allBatch.data)

    } catch (error) {

    }
  }

  addBatch() {
    const dialogRef = this.dialog.open(BatchDialogComponent, {
      data: {
        ui: 'AddBatch'
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.getAllBatch()
    })
  }

  async deleteCard(batch) {
    try {
      await this.batchService.deleteBatch(batch)
      this.getAllBatch()
    } catch (error) {

    }
  }

  editCard(batch) {
    const dialogRef = this.dialog.open(BatchDialogComponent, {
      data: {
        ui: 'EditBatch',
        batch: batch
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.getAllBatch()
    })
  }


}
