<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Dashboard</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div>
  <h3>Member Upcoming Expiry Report</h3>
</div>
<div class="row">
  <div class="col-lg-3 col-6" *ngFor="let expiry of memberExpireyGrid">
    <app-smartcard
      [title]="expiry.name"
      [count]="expiry.count"
      [titleClass]="expiry.className"
      descIconClass="ion-person-add"
      (click)="btnMemberContextDetail(expiry)"
    >
    </app-smartcard>
  </div>
</div>
<br />
<div>
  <h3>Today Report</h3>
</div>

<div class="row">
  <div class="col-lg-3 col-6" *ngFor="let todayEvent of memberTodayEvent">
    <app-smartcard
      [title]="todayEvent.name"
      [count]="todayEvent.count"
      [titleClass]="todayEvent.className"
      descIconClass="ion-person-add"
      (click)="btnMemberContextDetail(todayEvent)"
    >
    </app-smartcard>
  </div>
</div>
<br />
<div>
  <h3>Member Registeration Report</h3>
</div>
<div class="row">
  <div class="col-lg-3 col-6" *ngFor="let registration of membersDetailsGrid">
    <app-smartcard
      [title]="registration.name"
      [count]="registration.count"
      [titleClass]="registration.className"
      descIconClass="ion-person-add"
      (click)="btnMemberContextDetail(registration)"
    >
    </app-smartcard>
  </div>
</div>
