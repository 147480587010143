<div style="display: flex; padding-top: 0.3rem;">
  <div style="display: flex;" class="first-divider">
    <div *ngIf="firstLabel && firstText" style="flex: 1;">
      <div class="f16">
        {{firstLabel}}:
      </div>

      <div class="f15">
        {{firstText}}
      </div>
    </div>

    <div *ngIf="secondLabel && secondText" style="flex: 1;">
      <div class="f16">
        {{secondLabel}}:
      </div>

      <div class="f15">
        {{secondText}}
      </div>
    </div>
  </div>

  <div class="second-divider d-flex align-center justify-content-center">
    <div *ngIf="editIcon">
      <button mat-button color="primary" (click)="editClick()">
        <mat-icon>create</mat-icon>
      </button>
    </div>

    <div *ngIf="deleteIcon">
      <button mat-button color="warn" (click)="deleteClick()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>
