export default class Response {
    static parseGymPlan(plans: any[]): Parser[] {
        let newPlan: Parser[] = []
        for (const plan of plans) {
            let {
                price,
                name,
                month
            } = plan
            let newPlanDetail = new Parser(plan)
            newPlanDetail.addItem([
                new ParserItem('Plan Name', name),
                new ParserItem('Month', month),
            ])

            newPlanDetail.addItem([
                new ParserItem('Total Amount', price)
            ])
            newPlan.push(newPlanDetail)
        }
        return newPlan
    }

    static parseBatch(batchs: any[]): Parser[] {
        let newBatchs: Parser[] = []

        for (const batch of batchs) {
            let {
                batchLimit,
                currentMember,
                endTime,
                name,
                startTime,
            } = batch
            let newBatchDetail = new Parser(batch)

            newBatchDetail.addItem([
                new ParserItem('Batch Name', name),
                new ParserItem('Batch Limit', batchLimit)
            ])

            newBatchDetail.addItem([
                new ParserItem('Available Limit', batchLimit - currentMember),
                new ParserItem('Batch Time', startTime + ' to ' + endTime)
            ])

            newBatchDetail.addItem([
                new ParserItem('Total Members In Batch', currentMember)
            ])
            newBatchs.push(newBatchDetail)
        }
        return newBatchs
    }
}


class Parser {
    parse: ParserItem[][] = []
    constructor(public orignal: any) {

    }
    addItem(parseItem: ParserItem[]) {
        this.parse.push(parseItem)
    }
}

class ParserItem {
    constructor(public title: string, public value: string | number) {
    }
}

export { Response }