import Constant from 'src/constant'
import { BatchService } from '../batch/batch.service'
import { Component, OnInit } from '@angular/core'
import { MemberService } from 'src/app/component/members/member.service'
import { PlanService } from '../plan/plan.service'
import { Router } from '@angular/router'
import { UtilService } from 'src/app/service/util/util.service'

@Component({
  selector: 'app-member',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  members = []
  plans = []
  batches = []
  // Member filter
  searchText: string = null
  selectedMemberFilter: string = Constant.MEMBER_FILTER.ALL
  selectedTraining: string = 'null'
  selectedPlanFilter: string = 'null'
  selectedBatchFilter: string = 'null'

  ADD_MEMBER_URL = Constant.ROUTERS.ADD_MEMBER

  constructor(private router: Router,
    private memberService: MemberService,
    private planService: PlanService,
    private batchService: BatchService,
    private utilService: UtilService) { }
  ngOnInit(): void {
    this.getAllMembers()
    this.getAllPlan()
    this.getAllBatch()
  }

  async getAllPlan() {
    try {
      let plans = await this.planService.getAllPackage()
      this.plans = plans.data
    } catch (error) {

    }
  }

  async getAllBatch() {
    try {
      let batch = await this.batchService.getBatch()
      this.batches = batch.data
    } catch (error) {

    }
  }

  async getAllMembers(fromStart: boolean = true) {
    try {
      if (fromStart) {
        // Fetch the data from start
        let members = await this.memberService.getAllMembers(
          0,
          this.searchText,
          this.selectedMemberFilter,
          this.selectedTraining,
          this.selectedPlanFilter,
          this.selectedBatchFilter)
        this.members = members.data
      } else {
        // Fetch the data from middle
        let members = await this.memberService.getAllMembers(
          this.members.length,
          this.searchText,
          this.selectedMemberFilter,
          this.selectedTraining,
          this.selectedPlanFilter,
          this.selectedBatchFilter)
        this.members = [
          ...this.members,
          ...members.data
        ]
      }

    } catch (error) {

    }
  }



  /**
   * View Member Detail Screen
   * @param member 
   */
  viewMember(member: any) {
    this.router.navigate(['/member/' + member._id]);

  }

  /**
   * Delete Member
   */
  async deleteMember(member: any) {
    try {
      let memberId = member._id
      await this.memberService.deleteMemberApi(memberId)
      let deletedMemberIndex = this.members.findIndex(member => member._id === memberId)
      if (this.utilService.isFillArray(this.members) && deletedMemberIndex >= 0) {
        this.members.splice(deletedMemberIndex, 1)
      }
    } catch (error) {

    }

  }

  onScroll() {
    this.getAllMembers(false)
  }

  changeMemberFilter() {
    this.getAllMembers()
  }

  isFillArray(data: any[]) {
    return this.utilService.isFillArray(data)
  }
}
