import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
  } from '@angular/core'

@Component({
  selector: 'app-split-text',
  templateUrl: './split-text.component.html',
  styleUrls: ['./split-text.component.scss']
})
export class SplitTextComponent implements OnInit {

  @Input() firstLabel
  @Input() firstText
  @Input() secondLabel
  @Input() secondText

  // Card actions
  @Input() editIcon
  @Output() btnEditClick = new EventEmitter<any>()

  @Input() deleteIcon
  @Output() btnDeleteClick = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  editClick() {
    this.btnEditClick.emit()
  }

  deleteClick() {
    this.btnDeleteClick.emit()
  }

}
