import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
  } from '@angular/core'
import { countryCodes } from 'src/app/service/util/country-code'
import { FormControl } from '@angular/forms'
import { ReplaySubject } from 'rxjs'
import { UtilService } from 'src/app/service/util/util.service'

@Component({
  selector: 'app-mobile-picker',
  templateUrl: './mobile-picker.component.html',
  styleUrls: ['./mobile-picker.component.scss']
})
export class MobilePickerComponent implements OnInit {

  @Input() selectCountycode: string
  @Output() onCountryCodeSelected: EventEmitter<any> = new EventEmitter<any>();

  countryCodes = countryCodes



  filterCountryCode: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  countryCode = new FormControl('');
  countryCodeFilter = new FormControl('');


  constructor(private util: UtilService) { }

  ngOnInit(): void {

    if (this.util.isNotNull(this.selectCountycode)) {
      let country = this.countryCodes.find((country) => country.dialCode === this.selectCountycode)
      if (this.util.isNotNull(country)) {
        this.countryCode.setValue(country);
      } else {
        this.countryCode.setValue(this.countryCodes[0])
      }
    } else {
      this.countryCode.setValue(this.countryCodes[0])
    }

    this.onCountryCodeSelected.emit(this.countryCode.value)


    // load the initial bank list
    this.filterCountryCode.next(this.countryCodes.slice());

    this.countryCodeFilter.valueChanges
      .subscribe(() => {
        if (!this.countryCodes) {
          return;
        }
        // get the search keyword
        let search = this.countryCodeFilter.value;
        if (!search) {
          this.filterCountryCode.next(this.countryCodes.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the banks
        this.filterCountryCode.next(
          this.countryCodes.filter(country => country.name.toLowerCase().indexOf(search) > -1)
        );
      });

    this.countryCode.valueChanges
      .subscribe(() => {
        let search = this.countryCode.value;
        this.onCountryCodeSelected.emit(search)

      })
  }
}
