<div class="container-fluid">
  <h5>{{pageTitle}}</h5>
  <form [formGroup]="plansFormGroup">
    <div class="m-1rm align-center">
      <mat-form-field>
        <mat-label>Plan name</mat-label>
        <input
          matInput
          placeholder="Plan name eg. 6 month plan"
          formControlName="name"
          required
        />
      </mat-form-field>
      <br />

      <mat-form-field>
        <mat-label>Price</mat-label>
        <input
          matInput
          placeholder="Amount to paid"
          formControlName="price"
          required
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Select month</mat-label>
        <mat-select formControlName="month">
          <mat-option *ngFor="let plan of planMonths" [value]="plan.value">
            {{plan.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      <br />
      <br />
      <div class="col-12">
        <button
          mat-raised-button
          color="primary"
          mat-dialog-close
          (click)="closeDialog()"
        >
          Cancel
        </button>
        &nbsp; &nbsp;
        <button
          mat-raised-button
          color="primary"
          type="submit"
          (click)="onSubmit(plansFormGroup.value)"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</div>
