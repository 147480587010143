import * as CryptoJS from 'crypto-js'
import AES from 'crypto-js/aes'
import { Injectable } from '@angular/core'

const secret = '!!@@121'


@Injectable({
  providedIn: 'root'
})
export class EncryptService {

  constructor() { }

  encryptData(originalText: string) {
    if (!originalText) return
    return AES.encrypt(originalText, secret).toString();
  }

  decryptData(encryptText: string) {
    if (!encryptText) return
    let bytes = AES.decrypt(encryptText, secret);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText
  }
}
