import Constant from 'src/constant'
import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { DateTimeService } from 'src/app/service/util/date-time.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MemberService } from '../../member.service'
import { UtilService } from 'src/app/service/util/util.service'

@Component({
  selector: 'app-member-plan',
  templateUrl: './member-plan.component.html',
  styleUrls: ['./member-plan.component.scss']
})
export class MemberPlanComponent implements OnInit {

  planDetailFormGroup: FormGroup
  planEdit: boolean = true
  memberId: string

  plans = [];
  public selectedPlan: any = {}

  constructor(private _formBuilder: FormBuilder,
    private memberService: MemberService,
    private util: UtilService,
    private route: ActivatedRoute,
    private dateTime: DateTimeService) {

    this.route.params.subscribe((params) => {
      this.memberId = params['memberId']
    })

    this.route.queryParams.subscribe(params => {
      try {
        if (params['data'] && params['data'] != 'null') {
          this.selectedPlan = JSON.parse(params['data'])
          this.selectedPlan.price = this.selectedPlan.totalAmount
        }

        this.planEdit = params['isEdit'] === 'true'

      } catch (error) {
        this.selectedPlan = {}
      }

      console.log('this.selectedPlan', this.selectedPlan)
    });

    if (this.planEdit === false) {
      this.getMemberPre()
    }

  }

  ngOnInit(): void {
    this.planDetailFormGroup = this._formBuilder.group({
      selectPlan: [this.selectedPlan],
      renewDate: [null, Validators.required],
      paidAmount: [this.selectedPlan.paid, Validators.required],
      dueAmount: [{
        value: this.selectedPlan.pendingAmount,
        disabled: true
      }],
      comment: [null],
      discountType: [this.selectedPlan.discountType || 'percent'],
      discount: [this.selectedPlan.discount],
      admissionFees: [this.selectedPlan.admissionFees],
      // Edit Plan
      planExpiry: [this.selectedPlan.expiryDate]
    })
    if (this.planEdit === true) {
      this.planDetailFormGroup.patchValue({
        renewDate: 0
      })
    }
  }

  pendingPackageAmountCal(total, paid, discount, discountType) {
    let pendingAmount = 0
    let totalAfterDiscount = 0

    if (Constant.DISCOUNT_TYPE.AMOUNT.toLowerCase() === discountType && this.util.isNotNull(discount)) {
      totalAfterDiscount = total - discount
      pendingAmount = totalAfterDiscount - paid
    } else if (Constant.DISCOUNT_TYPE.PERCENT.toLowerCase() === discountType && this.util.isNotNull(discount)) {
      const discountAmount = Math.floor(total * (discount / 100))
      totalAfterDiscount = total - discountAmount
      pendingAmount = totalAfterDiscount - paid
    } else {
      totalAfterDiscount = total
      pendingAmount = totalAfterDiscount - paid
    }

    return {
      pendingAmount,
      totalAfterDiscount
    }
  }

  onChangePaidAmount() {
    let {
      selectPlan,
      paidAmount,
      discountType,
      discount
    } = this.planDetailFormGroup.value
    let calResult = this.pendingPackageAmountCal(selectPlan.price, paidAmount, discount, discountType)

    this.planDetailFormGroup.patchValue({
      dueAmount: calResult.pendingAmount
    })
  }


  async getMemberPre() {
    try {
      let member = await this.memberService.getMemberDetailForPreReq()
      this.plans = member.data.packages

      if (this.util.isFillArray(this.plans)) {
        this.planDetailFormGroup.patchValue({
          selectPlan: this.plans[0],
          dueAmount: this.plans[0].price
        })
      }

      let allPlan = await this.memberService.getPlanByMemberId(this.memberId)

      if (this.util.isFillArray(allPlan.data.packages)) {
        const lastPackage = allPlan.data.packages.sort(
          (a, b) =>
            this.dateTime.convertDateToMilli(b.expiryDate) -
            this.dateTime.convertDateToMilli(a.expiryDate)
        )

        this.planDetailFormGroup.patchValue({
          renewDate: this.dateTime.incrementDate(lastPackage[0].expiryDate, 'day', 1).toDate()
        })
      }
    } catch (error) {

    }
  }

  back() {
    this.util.popPage()
  }



  async btnSavePlan() {
    console.log(this)
    // Add Plan API
    if (this.planEdit === false) {
      this.planEdit

      const {
        paidAmount,
        selectPlan,
        renewDate,
        comment,
        discountType,
        discount,
        admissionFees
      } = this.planDetailFormGroup.value


      const memberPlan = {
        dateOfPurchase: this.dateTime.getYYYYMMDD(renewDate),
        paid: paidAmount,
        packageID: selectPlan._id,
        comment,
        discountType: discountType,
        discount,
        admissionFees
      }

      try {

        if (paidAmount <= selectPlan.price) {
          await this.memberService.addPlan(this.memberId, memberPlan)
          this.back()
        }
      } catch (error) {

      }
    } else if (this.planEdit === true) { // Edit Plan Screen

      const {
        paidAmount,
        selectPlan,
        planExpiry,
        comment,
        discountType,
        discount,
        admissionFees
      } = this.planDetailFormGroup.value

      let updatedPlan = {
        name: selectPlan.name,
        paid: paidAmount,
        totalAmount: selectPlan.price,
        comment,
        discount,
        discountType,
        expiryDate: this.dateTime.getYYYYMMDD(planExpiry),
        admissionFees
      }

      if (paidAmount <= selectPlan.price) {
        try {
          await this.memberService.editPlan(selectPlan._id, updatedPlan)
          this.back()
        } catch (error) {
        }
      }
    }

  }
}
