import { BatchService } from '../batch.service'
import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-batch-dialog',
  templateUrl: './batch-dialog.component.html',
  styleUrls: ['./batch-dialog.component.scss']
})
export class BatchDialogComponent implements OnInit {

  batchFormGroup: FormGroup
  pageTitle: string
  constructor(
    private _formbuilder: FormBuilder,
    private dialogRef: MatDialogRef<BatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private batchService: BatchService
  ) { }

  ngOnInit(): void {
    if (this.data.ui === 'AddBatch') {
      this.pageTitle = 'Add Batch'
      this.batchFormGroup = this._formbuilder.group({
        name: ['', Validators.required],
        batchLimit: ['', Validators.required],
        startTime: ['', Validators.required],
        endTime: ['', Validators.required]
      })
    } else {
      this.pageTitle = 'Edit Batch'
      this.batchFormGroup = this._formbuilder.group({
        name: [this.data.batch.name, Validators.required],
        batchLimit: [this.data.batch.batchLimit, Validators.required],
        startTime: [this.data.batch.startTime, Validators.required],
        endTime: [this.data.batch.endTime, Validators.required]
      })
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }

  async onSubmit(value) {
    try {
      if (this.data.ui === 'AddBatch') {
        await this.batchService.addBatch(value)
        this.dialogRef.close()
      } else {
        await this.batchService.editBatch({
          ...this.data.batch,
          ...value
        })
        this.dialogRef.close()
      }
    } catch (error) {

    }
  }
}
