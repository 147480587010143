import { Component, OnInit } from '@angular/core'
import { SubscriberService } from 'src/app/service/api/subscriber.service'
import { UtilService } from 'src/app/service/util/util.service'

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  constructor(private subscriber: SubscriberService,
    private util: UtilService) { }

  plans = []

  ngOnInit(): void {
    this.getSubscriptioDetail()
  }

  async getSubscriptioDetail() {
    try {
      let subscription = await this.subscriber.getActiveSubscriptionAPI()
      this.plans = subscription.data
    } catch (error) {
    }
  }

  async buyNowClick(type: string) {
    this.util.redirectToPayment(type)
  }
}
