import { catchError } from 'rxjs/operators'
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
  } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { Observable, of, throwError } from 'rxjs'
import { Router } from '@angular/router'
import { UtilService } from '../../util/util.service'

//for throw:

@Injectable({
  providedIn: 'root'
})
export class ResponseService implements HttpInterceptor {

  constructor(private router: Router, private util: UtilService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      this.util.logoutAuth()
      //navigate /delete cookies or whatever
      // this.router.navigateByUrl(`/login`);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
  }
}
