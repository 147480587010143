import * as moment from 'moment-timezone'
import Constant from 'src/constant'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MemberService } from '../member.service'
import { Router } from '@angular/router'
import { UtilService } from 'src/app/service/util/util.service'
@Component({
  selector: 'app-addmember',
  templateUrl: './addmember.component.html',
  styleUrls: ['./addmember.component.scss']
})
export class AddmemberComponent implements OnInit {

  isLinear = false;

  basicDetailFormGroup: FormGroup
  planDetailFormGroup: FormGroup
  personalDetailFormGroup: FormGroup
  selectedValue: string;

  plans = [];
  batches = []
  LIST_MEMBERS = Constant.ROUTERS.LIST_MEMBER
  MEMBER_DETAIL = Constant.ROUTERS.MEMBER

  constructor(private _formBuilder: FormBuilder,
    private memberService: MemberService,
    private util: UtilService,
    private router: Router) { }


  ngOnInit(): void {
    this.basicDetailFormGroup = this._formBuilder.group({
      name: [null, Validators.required],
      mobile: [null],
      countryCode: [null],
      callingCode: [null],
      trainingType: ['General training'],
      selectBatch: [null],
      membershipId: [null, Validators.required]
    })

    this.planDetailFormGroup = this._formBuilder.group({
      selectPlan: [{}],
      joiningDate: [null, Validators.required],
      paidAmount: [null, Validators.required],
      dueAmount: [{
        value: null,
        disabled: true
      }],
      comment: [null],
      discountType: ['percent'],
      discount: [null],
      admissionFees: [null]
    })

    this.personalDetailFormGroup = this._formBuilder.group({
      gender: ['male'],
      email: [null],
      dateOfBirth: [null],
      address: [null],
      notes: [null]
    })

    this.getMemberPre()
  }

  onCountryCodeSelected(countryCode: any) {
    this.basicDetailFormGroup.patchValue({
      countryCode: countryCode.code,
      callingCode: countryCode.dialCode
    })
  }

  async getMemberPre() {
    try {
      let member = await this.memberService.getMemberDetailForPreReq()
      this.plans = member.data.packages
      this.batches = member.data.batch
      this.basicDetailFormGroup.patchValue({
        membershipId: member.data.membershipId
      })

      if (this.util.isFillArray(this.plans)) {
        this.planDetailFormGroup.patchValue({
          selectPlan: this.plans[0],
          dueAmount: this.plans[0].price
        })


      }

      if (this.util.isFillArray(this.batches)) {
        this.batches = this.batches.map((item, index) => {
          let batchName = item.name

          if (this.util.isNumber(item.batchLimit) && this.util.isNumber(item.currentMember)) {
            batchName += ' AVBL-' + (item.batchLimit - item.currentMember).toString()
          }

          if (this.util.isNotNull(item.startTime) && this.util.isNotNull(item.endTime)) {
            batchName += `  ${item.startTime} - ${item.endTime}`
          }
          item.batchName = batchName
          return item
        })
      }
    } catch (error) {

    }
  }


  pendingPackageAmountCal(total, paid, discount, discountType) {
    let pendingAmount = 0
    let totalAfterDiscount = 0

    if (Constant.DISCOUNT_TYPE.AMOUNT.toLowerCase() === discountType && this.util.isNotNull(discount)) {
      totalAfterDiscount = total - discount
      pendingAmount = totalAfterDiscount - paid
    } else if (Constant.DISCOUNT_TYPE.PERCENT.toLowerCase() === discountType && this.util.isNotNull(discount)) {
      const discountAmount = Math.floor(total * (discount / 100))
      totalAfterDiscount = total - discountAmount
      pendingAmount = totalAfterDiscount - paid
    } else {
      totalAfterDiscount = total
      pendingAmount = totalAfterDiscount - paid
    }

    return {
      pendingAmount,
      totalAfterDiscount
    }
  }

  onChangePaidAmount() {
    let {
      selectPlan,
      paidAmount,
      discountType,
      discount
    } = this.planDetailFormGroup.value
    let calResult = this.pendingPackageAmountCal(selectPlan.price, paidAmount, discount, discountType)

    this.planDetailFormGroup.patchValue({
      dueAmount: calResult.pendingAmount
    })
  }

  /**
   * Save member click
   */
  async btnAddMember() {

    try {
      // if (paid * 1 <= packageD.price) {
      // }

      let {
        selectPlan,
        joiningDate,
        paidAmount,
        discountType,
        discount,
        admissionFees,
        comment
      } = this.planDetailFormGroup.value

      let {
        name,
        mobile,
        countryCode,
        callingCode,
        trainingType,
        selectBatch,
        membershipId,
      } = this.basicDetailFormGroup.value
      let {
        gender,
        email,
        dateOfBirth,
        address,
        notes
      } = this.personalDetailFormGroup.value

      let batchId = null
      if (selectBatch && selectBatch._id) {
        batchId = selectBatch._id
      }
      let note = null
      if (this.util.isNotNull(notes)) {
        note = notes
      }

      const memberAddingInput = {
        // Personal Info
        name,
        mobile,
        countryCode,
        callingCode,
        trainingType,
        email,
        dob: dateOfBirth,
        address,
        admissionFees,
        gender,
        notes: note,
        batchId,
        membershipId,
        packageID: selectPlan._id,
        dateOfJoing: moment(joiningDate, 'YYYY-MM-DD'),
        discountType: discountType,
        discount,
        paid: paidAmount,
        comment
      }

      let addedMember = await this.memberService.addMemberAPI(memberAddingInput)
      // Navigate to member detail
      this.router.navigate([this.MEMBER_DETAIL + '/' + addedMember.data._id], { replaceUrl: true })
    } catch (error) {

    }
  }
}
