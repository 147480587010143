<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Add Member</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item">
            <a
              [routerLink]="[LIST_MEMBERS]"
              routerLinkActive="router-link-active"
              >Member</a
            >
          </li>
          <li class="breadcrumb-item active">Add Member</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row">
      <mat-horizontal-stepper [linear]="isLinear" #stepper style="width: 100%;">
        <mat-step [stepControl]="basicDetailFormGroup">
          <form [formGroup]="basicDetailFormGroup">
            <ng-template matStepLabel>Basic Detail</ng-template>
            <div class="container-fluid">
              <div class="m-1rm align-center">
                <form-row>
                  <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input
                      matInput
                      placeholder="Name"
                      formControlName="name"
                      required
                    />
                  </mat-form-field>
                </form-row>
                <br />
                <div>
                  <div class="row" style="padding: 0 7.5px;">
                    <div class="col-xs-12 col-md-3 col-lg-2">
                      <app-mobile-picker
                        selectCountycode="91"
                        (onCountryCodeSelected)="onCountryCodeSelected($event)"
                      ></app-mobile-picker>
                    </div>
                    <div class="col-xs-12 col-md-9 col-lg-4">
                      <mat-form-field class="full-width">
                        <input
                          type="number"
                          matInput
                          id="phone"
                          formControlName="mobile"
                          name="phone"
                          placeholder="Phone"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <br />
                <form-row>
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="trainingType"
                  >
                    <span class="pb-2 display-inline-block">
                      Training Type
                    </span>
                    <br />
                    <mat-radio-button value="General training"
                      >General Training</mat-radio-button
                    >
                    &nbsp; &nbsp;
                    <mat-radio-button value="Personal training"
                      >Personal Training</mat-radio-button
                    >
                  </mat-radio-group>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Membership Id</mat-label>
                    <input
                      matInput
                      placeholder="Membership Id"
                      formControlName="membershipId"
                      required
                      type="number"
                    />
                  </mat-form-field>
                </form-row>
                <form-row>
                  <mat-form-field appearance="fill">
                    <mat-label>Select Batch</mat-label>
                    <mat-select name="food" formControlName="selectBatch">
                      <mat-option *ngFor="let batch of batches" [value]="batch">
                        {{batch.batchName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </form-row>
                <br />
                <div class="col-12">
                  <button
                    mat-raised-button
                    color="primary"
                    matStepperNext
                    [disabled]="!basicDetailFormGroup.valid"
                  >
                    Next
                    <mat-icon>arrow_forward_ios</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="planDetailFormGroup">
          <form [formGroup]="planDetailFormGroup">
            <ng-template matStepLabel>Plan Detail</ng-template>

            <div class="container-fluid">
              <div class="m-1rm align-center">
                <form-row>
                  <mat-form-field appearance="fill">
                    <mat-label>Select Plan</mat-label>
                    <mat-select formControlName="selectPlan" required>
                      <mat-option *ngFor="let plan of plans" [value]="plan">
                        {{plan.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Plan Amount</mat-label>
                    <input
                      matInput
                      disabled
                      [value]="planDetailFormGroup.value.selectPlan.price"
                    />
                    <!-- formControlName="selectedPlanAmount" -->
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Joining Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="joiningDate"
                      (focus)="joiningDate.open()"
                      (click)="joiningDate.open()"
                      formControlName="joiningDate"
                      required
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="joiningDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #joiningDate></mat-datepicker>
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Paid Amount</mat-label>
                    <input
                      matInput
                      formControlName="paidAmount"
                      (keyup)="onChangePaidAmount()"
                      type="number"
                      required
                    />
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Due Amount</mat-label>
                    <input matInput formControlName="dueAmount" />
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Comments</mat-label>
                    <input
                      matInput
                      formControlName="comment"
                      placeholder="Comment eg. cash payment, check payment"
                    />
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="discountType"
                    (change)="onChangePaidAmount()"
                  >
                    <span class="display-inline-block pb-2">
                      Discount Type
                    </span>
                    <br />
                    <mat-radio-button value="percent">Percent</mat-radio-button>
                    &nbsp; &nbsp;
                    <mat-radio-button value="amount">Amount</mat-radio-button>
                  </mat-radio-group>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Discount</mat-label>
                    <input
                      matInput
                      placeholder="Discount"
                      formControlName="discount"
                      type="number"
                      (keyup)="onChangePaidAmount()"
                    />
                  </mat-form-field>
                </form-row>
                <form-row>
                  <mat-form-field>
                    <mat-label>Admission Fees</mat-label>
                    <input
                      matInput
                      placeholder="Addmission fees"
                      formControlName="admissionFees"
                      type="number"
                    />
                  </mat-form-field>
                </form-row>
                <br />
                <div class="col-12">
                  <button mat-raised-button matStepperPrevious color="primary">
                    <mat-icon>arrow_back_ios</mat-icon>Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    mat-raised-button
                    matStepperNext
                    color="primary"
                    [disabled]="!planDetailFormGroup.valid"
                  >
                    Next
                    <mat-icon>arrow_forward_ios</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div></div>
          </form>
        </mat-step>

        <mat-step [stepControl]="personalDetailFormGroup">
          <form [formGroup]="personalDetailFormGroup">
            <ng-template matStepLabel>Personal details</ng-template>
            <div class="container-fluid">
              <div class="m-1rm align-center">
                <form-row>
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="gender"
                  >
                    <span class="pb-2 display-inline-block">
                      Gender
                    </span>
                    <br />
                    <mat-radio-button value="male">Male</mat-radio-button>
                    &nbsp;&nbsp;
                    <mat-radio-button value="female">Female</mat-radio-button>
                  </mat-radio-group>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input
                      matInput
                      placeholder="Email"
                      formControlName="email"
                      type="email"
                    />
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Date of Birth</mat-label>
                    <input
                      matInput
                      (click)="dateOFBirth.open()"
                      (focus)="dateOFBirth.open()"
                      [matDatepicker]="dateOFBirth"
                      formControlName="dateOfBirth"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="dateOFBirth"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #dateOFBirth></mat-datepicker>
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Address</mat-label>
                    <input
                      matInput
                      placeholder="Address"
                      formControlName="address"
                    />
                  </mat-form-field>
                </form-row>

                <form-row>
                  <mat-form-field>
                    <mat-label>Notes</mat-label>
                    <input
                      matInput
                      placeholder="Notes"
                      formControlName="notes"
                    />
                  </mat-form-field>
                </form-row>
                <br />
                <div class="col-12">
                  <button mat-raised-button matStepperPrevious color="primary">
                    <mat-icon>arrow_back_ios</mat-icon>Back
                  </button>
                  &nbsp; &nbsp;

                  <button
                    mat-raised-button
                    color="primary"
                    (click)="btnAddMember()"
                    [disabled]="!personalDetailFormGroup.valid"
                  >
                    Save
                    <mat-icon>save</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <!-- /.row -->
  </div>
</section>
