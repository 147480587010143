<h5 mat-dialog-title>Are you sure?</h5>
<div mat-dialog-content>
  <p>You want to delete.</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="delete()" cdkFocusInitial>
    Delete
  </button>
</div>
