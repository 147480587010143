import Constant from 'src/constant'
import { AfterViewInit, Component, OnInit } from '@angular/core'
import { DateTimeService } from 'src/app/service/util/date-time.service'
import { FirebaseAnalyticsService } from 'src/app/service/util/firebase-analytics.service'
import { SubscriberService } from 'src/app/service/api/subscriber.service'
import { UtilService } from 'src/app/service/util/util.service'
declare var $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  routes = Constant.ROUTERS

  subscriber = {
    email: '',
    subLogo: '',
    subName: '',
    subscriptionExp: '',
    userName: ''
  }

  constructor(private util: UtilService,
    private subscriberService: SubscriberService,
    private dateTime: DateTimeService,
    private firebaseAnalytic: FirebaseAnalyticsService) { }

  ngOnInit(): void {
    this.getLoginUserDetail()
  }

  ngAfterViewInit(): void {
    // Handle close drawer
    $('.nav-item').click(() => {
      if ($('body').hasClass("sidebar-mini") && $('body').hasClass("sidebar-open")) {
        $('body').addClass("sidebar-collapse");
        $('body').removeClass("sidebar-open");
      }
    })
  }

  logout() {
    this.util.logoutAuth()
  }

  async getLoginUserDetail() {
    try {
      let subs = await this.subscriberService.getSubscriberDetail()
      let {
        email,
        subLogo,
        subName,
        subscriptionExp,
        admin,
        userID
      } = subs.data
      this.subscriber = {
        email,
        subLogo,
        subName,
        subscriptionExp,
        userName: admin.name
      }
      this.navlinks[0].title = subName
      this.firebaseAnalytic.addUserToFirebase(userID)
    } catch (error) {

    }
  }

  navlinks = [
    {
      title: 'GymName',
      header: true
    },
    {
      title: 'Subscription',
      link: this.routes.SUBSCRIPTION,
      icon: 'fas fa-money-bill-alt'
    },
    {
      title: 'DashBoard',
      link: this.routes.DASHBOARD,
      icon: 'fas fa-tachometer-alt'
    },
    // {
    //   title: 'Collection',
    //   link: this.routes.COLLECTION,
    //   icon: 'far fa-money-bill-alt'
    // },
    // {
    //   title: 'Manage Trainer/Staff',
    //   link: this.routes.MANAGESTAFF,
    //   icon: 'fas fa-users'
    // },
    {
      title: 'Download Member',
      link: this.routes.DOWNLOAD_MEMBER,
      icon: 'fas fa-download'
    },
    {
      title: 'Manage Gym',
      header: true
    },
    {
      title: 'Members',
      link: this.routes.LIST_MEMBER,
      icon: 'fas fa-money-bill-alt'
    },
    // {
    //   title: 'Visitor',
    //   link: this.routes.VISITOR,
    //   icon: 'fas fa-tachometer-alt'
    // },
    // {
    //   title: 'Expense',
    //   link: this.routes.EXPENSE,
    //   icon: 'fas fa-users'
    // },
    {
      title: 'Master',
      header: true
    },
    {
      title: 'Plan',
      link: this.routes.GYM_PLAN,
      icon: 'fas fa-th-large'
    },
    // {
    //   title: 'Services',
    //   link: this.routes.GYM_SERVICE,
    //   icon: 'fas fa-cogs'
    // },
    // {
    //   title: 'SMS/Whatus up',
    //   link: this.routes.GYM_WHATSUP_TEMPLATE,
    //   icon: 'fab fa-whatsapp'
    // },
    {
      title: 'Batch',
      link: this.routes.GYM_BATCH,
      icon: 'fas fa-book'
    }
  ]

  getDDMMMYYYYFormat(date) {
    return this.dateTime.getDDMMMYYYYFormat(date)
  }

}
