<app-layout-header>
  <h1>Batch</h1>
  <div class="offset-4 col-sm-2" style="text-align: end;">
    <button (click)="addBatch()" class="btn btn-sm btn-success">
      &nbsp;
      <i class="fas fa-book"></i>
      &nbsp; Add Batch
    </button>
  </div>
</app-layout-header>

<div class="pt-3">
  <detail-card
    [allItems]="batchs"
    (deleteCard)="deleteCard($event)"
    (editCard)="editCard($event)"
  ></detail-card>
</div>
