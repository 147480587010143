<div>
  <div>Notes:</div>
  <ol start="1">
    <li>Select type of report you want to download</li>
    <li>Click download button</li>
    <li>Check download folder for excel file</li>
  </ol>
</div>
<div class="row mt-3">
  <div class="col-lg-4 col-xl-3 col-md-4 col-sm-12 col-xs-12 text-center">
    <mat-form-field>
      <mat-label>Type Of Report</mat-label>
      <mat-select [(ngModel)]="selectedReport" name="food">
        <mat-option
          *ngFor="let excelReport of MEMBER_EXCEL_DOWNLOAD_FILTER"
          [value]="excelReport"
        >
          {{excelReport.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-stroked-button
      class="button-gym-outline"
      (click)="downloadReport()"
    >
      <mat-icon>get_app</mat-icon> Download
    </button>
  </div>
</div>
