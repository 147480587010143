<div class="row">
  <div
    class="col-lg-4 col-xl-3 col-md-4 col-sm-12 col-xs-12 detail-card-container"
    *ngFor="let items of allItems"
  >
    <!-- /.card-header -->

    <mat-card>
      <mat-card-content
        *ngFor="let detail of items.parse let first = first;"
        style="display: flex"
      >
        <div *ngFor="let item of detail" [class.mt10]="!first" style="flex: 1;">
          <div style="color: #006064; font-size: 16px;">
            {{item.title}}:
          </div>

          <div style="font-size: 15px;">
            {{item.value}}
          </div>
        </div>
      </mat-card-content>

      <mat-card-actions>
        <div class="text-right">
          <button mat-button color="warn" (click)="deleteItem(items.orignal)">
            <mat-icon>delete</mat-icon> Delete
          </button>

          <button
            mat-button
            color="primary"
            (click)="editCard.emit(items.orignal)"
          >
            <mat-icon>create</mat-icon> Edit
          </button>

          <!-- &nbsp; -->
          <!-- <button class="btn btn-sm btn-primary" (click)="viewCard(items.orignal)">
            <i class="fas fa-file-contract"></i> &nbsp; View
          </button> -->
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
