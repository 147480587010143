import { CookieService } from 'ngx-cookie-service'
import { EncryptService } from '../encrypt/encrypt.service'
import { environment } from '../../../environments/environment'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(private cookieService: CookieService,
    private encrypt: EncryptService) {

  }

  set(key, value) {
    this.cookieService.set(key, this.encrypt.encryptData(value), {
      domain: environment.BASE_URL,
      path: '/',
      secure: false,
      sameSite: 'Lax'
    })
  }


  get(key) {
    return this.encrypt.decryptData(this.cookieService.get(key))
  }

  delete(key) {
    this.cookieService.delete(key, '/', environment.BASE_URL, false, 'Lax')
  }
}
