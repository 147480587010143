import {
    Component, OnInit,

} from '@angular/core'

@Component({
    selector: 'form-row',
    templateUrl: './from-row.component.html',
    styleUrls: ['./from-row.component.scss']
})
export class FromRowComponent implements OnInit {



    constructor() { }
    ngOnInit(): void {
    }
}
