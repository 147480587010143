import { ApiService } from './api.service'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private api: ApiService) { }

  getDashboardStats() {
    let options = this.api.getOptions('/member/dashboard/member-report')
    return this.api.get(options);
  }

  getCollectionStat(startDate?: number, endDate?: number) {
    let options = this.api.getOptions('/member/dashboard/collection', null, {
      startDate,
      endDate,
      apiVersion: 1
    })
    return this.api.get(options);
  }

  /**
   * Replace old token with new token with new token
   */
  exchangeToken() {
    let options = this.api.getOptions('/subscriber/exchange-token')
    return this.api.get(options);
  }

}
