import Constant from 'src/constant'
import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { PlanService } from '../plan.service'

@Component({
  selector: 'app-gym-plan-dialog',
  templateUrl: './gym-plan-dialog.component.html',
  styleUrls: ['./gym-plan-dialog.component.scss']
})
export class GymPlanDialogComponent implements OnInit {
  static GYM_ADD_PLAN = 'AddPlan'
  pageTitle: string
  plansFormGroup: FormGroup

  constructor(private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<GymPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private planService: PlanService) { }


  planMonths = Constant.PLAN_MONTHS

  ngOnInit(): void {
    if (this.data.ui === GymPlanDialogComponent.GYM_ADD_PLAN) {
      this.pageTitle = 'Add Plan'
      this.plansFormGroup = this._formBuilder.group({
        name: ['', Validators.required],
        price: ['', Validators.required],
        month: [1, Validators.required]
      })
    } else {
      let {
        month,
        name,
        price
      } = this.data.plan
      this.pageTitle = 'Edit Plan'
      this.plansFormGroup = this._formBuilder.group({
        name: [name, Validators.required],
        price: [price, Validators.required],
        month: [month, Validators.required]
      })
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }

  async onSubmit(value) {
    // Add plan api
    if (this.data.ui === GymPlanDialogComponent.GYM_ADD_PLAN) {
      try {
        await this.planService.addPlan(value)
        this.dialogRef.close()
      } catch (error) {

      }

    } else {
      // Edit plan api
      try {
        await this.planService.editPlan({
          ...this.data.plan,
          ...value
        })
        this.dialogRef.close()
      } catch (error) {

      }
    }
  }
}
