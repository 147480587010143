import Constant from 'src/constant'
import { ActivatedRoute, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { DateTimeService } from 'src/app/service/util/date-time.service'
import { DeleteDialogComponent } from 'src/app/shared/component/delete-dialog/delete-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { MemberService } from '../member.service'

@Component({
  selector: 'app-memberdetail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss']
})
export class MemberdetailComponent implements OnInit {
  memberId: string = null
  memberDetail: any = { batch: {}, packages: [] }
  batch: any = {}
  packages: any[] = []
  LIST_MEMBER = Constant.ROUTERS.LIST_MEMBER

  constructor(private _activatedroute: ActivatedRoute,
    private memberService: MemberService,
    private dateTimeUtil: DateTimeService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.memberId = this._activatedroute.snapshot.paramMap.get("memberId");
    this.getMemberDetail()
  }

  async getMemberDetail() {
    try {
      let memberDetail = await this.memberService.getMemberDetail(this.memberId)
      this.memberDetail = memberDetail.data
      if (this.memberDetail.batch) {
        this.batch = this.memberDetail.batch
      }
      if (Array.isArray(this.memberDetail.packages)) {
        this.packages = this.memberDetail.packages
      }
    } catch (error) {


    }
  }

  getDDMMMYYYYFormat(date) {
    return this.dateTimeUtil.getDDMMMYYYYFormat(date)
  }

  getHumanDateSort(date) {
    return this.dateTimeUtil.getHumanDateSort(date)
  }

  /**
   * edit member when user click on edit member
   * @param member 
   */
  editMember(member) {
    this.router.navigate(['/member/' + member._id + '/edit'], { queryParams: { data: JSON.stringify(member) } });
  }

  btnRenewOrEditPlan(memberId, plan, isEdit: boolean) {
    this.router.navigate(['/member/' + memberId + '/plan'], { queryParams: { data: JSON.stringify(plan), isEdit: isEdit } });
  }

  async btnDeletePlan(memberId: string, planId: string) {

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
    })
    dialogRef.afterClosed()
      .subscribe(async (result) => {
        try {
          if (result) {
            let result = await this.memberService.deletePlan(memberId, planId)
            this.ngOnInit()
          }
        } catch (error) {

        }
      })
  }

  async btnViewAllPlan(memberId) {
    try {
      let plans = await this.memberService.getPlanByMemberId(memberId)
      this.packages = plans.data.packages
    } catch (error) {

    }
  }

}
