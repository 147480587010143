export const environment = {
    production: true,
    APP_BASE_URL: 'https://devapp.gymbook.in',
    AUTH_APP_BASE_URL: 'https://authdev.gymbook.in',
    AUTH_APP_LOGOUT_URL: 'https://authdev.gymbook.in/logout',
    BASE_URL: '.gymbook.in',
    BASE_API_URL: 'https://dev-0.gymbook.in',
    PAYMENT_PURCHASE_PLAN: 'https://devpayments.gymbook.in/plan',
    PAYMENT_PURCHASE_SMS: 'https://devpayments.gymbook.in/sms-buy',
    firebaseConfig: {
        apiKey: "AIzaSyBMFSv0_zLqp-a1374diVgomxONS2fYKj4",
        authDomain: "gymbookwebappdev.firebaseapp.com",
        databaseURL: "https://gymbookwebappdev.firebaseio.com",
        projectId: "gymbookwebappdev",
        storageBucket: "gymbookwebappdev.appspot.com",
        messagingSenderId: "709922240797",
        appId: "1:709922240797:web:badf811b0129e93837c9f4",
        measurementId: "G-9762Y48REN"
    }
};