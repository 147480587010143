import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'
import { AuthService } from './auth.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UtilService } from '../service/util/util.service'


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
    private util: UtilService) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLogin()) {
      return true;
    } else {
      // redirect to login page
      this.util.redirectUrlToAuth(state.url)
    }
  }

}
