<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div
        class="col-12"
        style="display: flex; justify-content: space-between;"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>
