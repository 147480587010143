import { AddmemberComponent } from './component/members/addmember/addmember.component'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { AuthService } from './auth/auth.service'
import { BatchComponent } from './component/batch/batch.component'
import { BatchDialogComponent } from './component/batch/batch-dialog/batch-dialog.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { CollectionComponent } from './component/collection/collection.component'
import { CookieService } from 'ngx-cookie-service'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { DashboardComponent } from './component/dashboard/dashboard.component'
import { DeleteDialogComponent } from './shared/component/delete-dialog/delete-dialog.component'
import { DetailcardComponent } from './shared/component/detailcard/detailcard.component'
import { DownloadMemberComponent } from './component/download-member/download-member.component'
import { EditMemberComponent } from './component/members/member-detail/edit-member/edit-member.component'
import { ExpenseComponent } from './component/expense/expense.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FromRowComponent } from './shared/component/from-row/from-row.component'
import { GymPlanDialogComponent } from './component/plan/gym-plan-dialog/gym-plan-dialog.component'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { LayoutComponent } from './component/layout/layout.component'
import { LayoutHeaderComponent } from './shared/component/layout-header/layout-header.component'
import { LoaderService } from './service/api/interceptor/loader.service'
import { ManagestaffComponent } from './component/managestaff/managestaff.component'
import { MaterialModule } from './material.module'
import { MembercardComponent } from './shared/component/membercard/membercard.component'
import { MemberdetailComponent } from './component/members/member-detail/member-detail.component'
import { MemberPlanComponent } from './component/members/member-detail/member-plan/member-plan.component'
import { MembersComponent } from './component/members/members.component'
import { MembersContextComponent } from './component/members/members-context/members-context.component'
import { MobilePickerComponent } from './shared/component/mobile-picker/mobile-picker.component'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { NgxSpinnerModule } from 'ngx-spinner'
import { PlanComponent } from './component/plan/plan.component'
import { ResponseService } from './service/api/interceptor/response.service'
import { ServiceComponent } from './component/service/service.component'
import { SmartcardComponent } from './shared/component/smartcard/smartcard.component'
import { SplitTextComponent } from './shared/component/split-text/split-text.component'
import { SubscriptionComponent } from './component/subscription/subscription.component'
import { ToastrModule } from 'ngx-toastr'
import { TokenService } from './service/api/interceptor/token.service'
import { VisitorComponent } from './component/visitor/visitor.component'
import { WhatsuptemplateComponent } from './component/whatsuptemplate/whatsuptemplate.component'


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    DashboardComponent,
    ManagestaffComponent,
    MembersComponent,
    VisitorComponent,
    ExpenseComponent,
    SubscriptionComponent,
    DownloadMemberComponent,
    PlanComponent,
    ServiceComponent,
    WhatsuptemplateComponent,
    BatchComponent,
    MembercardComponent,
    AddmemberComponent,
    MemberdetailComponent,
    SmartcardComponent,
    MobilePickerComponent,
    DetailcardComponent,
    FromRowComponent,
    GymPlanDialogComponent,
    BatchDialogComponent,
    DeleteDialogComponent,
    LayoutHeaderComponent,
    SplitTextComponent,
    CollectionComponent,
    MembersContextComponent,
    EditMemberComponent,
    MemberPlanComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxMaterialTimepickerModule,
    ToastrModule.forRoot(),
    InfiniteScrollModule
  ],
  providers: [CookieService, AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenService,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderService,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseService,
      multi: true
    }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
