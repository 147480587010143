import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
  } from '@angular/core'
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'detail-card',
  templateUrl: './detailcard.component.html',
  styleUrls: ['./detailcard.component.scss']
})
export class DetailcardComponent implements OnInit {

  @Output() viewCard = new EventEmitter<any>()
  @Output() deleteCard = new EventEmitter<any>()
  @Output() editCard = new EventEmitter<any>()
  @Input() allItems: any = []


  constructor(private dialog: MatDialog) { }
  ngOnInit(): void {
  }

  deleteItem(item: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
    })
    dialogRef.afterClosed()
      .subscribe((result) => {
        if (result) {
          this.deleteCard.emit(item)
        }
      })
  }
}
