import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Location } from '@angular/common'
import { MemberService } from '../../member.service'
import { UtilService } from 'src/app/service/util/util.service'


@Component({
  selector: 'app-edit-member',
  templateUrl: './edit-member.component.html',
  styleUrls: ['./edit-member.component.scss']
})
export class EditMemberComponent implements OnInit {

  basicDetailFormGroup: FormGroup
  memberDetails: any;
  batches = []
  personalDetailFormGroup: FormGroup
  isLinear = false;


  constructor(private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private memberService: MemberService,
    private util: UtilService,
    private _location: Location) {
    this.route.queryParams.subscribe(params => {
      try {
        if (params['data']) {
          this.memberDetails = JSON.parse(params['data'])
        }
      } catch (error) {
        this.memberDetails = {}
      }
    });

    this.preLoadForm()
    this.getMemberPre()
  }

  ngOnInit(): void {
  }

  onCountryCodeSelected(countryCode: any) {
    this.basicDetailFormGroup.patchValue({
      countryCode: countryCode.code,
      callingCode: countryCode.dialCode
    })
  }

  preLoadForm() {
    this.basicDetailFormGroup = this._formBuilder.group({
      name: [this.memberDetails.name, Validators.required],
      mobile: [this.memberDetails.mobile],
      countryCode: [this.memberDetails.countryCode],
      callingCode: [this.memberDetails.callingCode],
      trainingType: [this.memberDetails.trainingType],
      selectBatch: [null],
      membershipId: [this.memberDetails.membershipId, Validators.required]
    })

    this.personalDetailFormGroup = this._formBuilder.group({
      gender: [this.memberDetails.gender],
      email: [this.memberDetails.email],
      dateOfBirth: [this.memberDetails.dob],
      address: [this.memberDetails.address],
      notes: [this.memberDetails.notes]
    })
  }

  async getMemberPre() {
    try {
      let member = await this.memberService.getMemberDetailForPreReq()
      this.batches = member.data.batch

      if (this.util.isFillArray(this.batches)) {
        this.batches.unshift({
          name: 'Select Batch',
          item: 'select_batch'
        })

        this.batches = this.batches.map((item, index) => {
          let batchName = item.name

          if (this.util.isNumber(item.batchLimit) && this.util.isNumber(item.currentMember)) {
            batchName += ' AVBL-' + (item.batchLimit - item.currentMember).toString()
          }

          if (this.util.isNotNull(item.startTime) && this.util.isNotNull(item.endTime)) {
            batchName += `  ${item.startTime} - ${item.endTime}`
          }
          item.batchName = batchName
          return item
        })



      }

      if (this.memberDetails && this.memberDetails.batchId) {
        let __batch = this.batches.find((item) => item._id === this.memberDetails.batchId)
        this.basicDetailFormGroup.patchValue({
          selectBatch: __batch
        })
      }



    } catch (error) {

    }
  }

  async btnAddMember() {
    try {
      let finalData = {
        ...this.basicDetailFormGroup.value,
        ...this.personalDetailFormGroup.value,
        batchId: this.basicDetailFormGroup.value.selectBatch._id,
        _id: this.memberDetails._id,
        dob: this.personalDetailFormGroup.value.dateOfBirth
      }
      delete finalData.selectBatch
      await this.memberService.updateProfile(finalData)
      this._location.back();
    } catch (error) {

    }
  }
}
