<div class="wrapper">
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav" style="width: 100%;">
      <li class="nav-item">
        <a [routerLink]="[routes.LIST_MEMBER]" class="nav-link">Members</a>
      </li>
      <!-- <li class="nav-item">
        <a [routerLink]="[routes.VISITOR]" class="nav-link">Visitor</a>
      </li>
      <li class="nav-item">
        <a [routerLink]="[routes.EXPENSE]" class="nav-link">Expense</a>
      </li> -->

      <li class="nav-item ml-auto">
        <button mat-button (click)="logout()">
          <div class="flex-center">
            <span style="font-size: 1.1rem;"> Log out</span>
            <div style="font-size: 1.5rem;margin-left: 4%;">
              <mat-icon [inline]="true">power_settings_new</mat-icon>
            </div>
          </div>
        </button>

        <!-- <a
        class="nav-link"
        data-widget="control-sidebar"
        data-slide="true"
        href="#"
        ><i class="fas fa-th-large"></i
      ></a> -->
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
  <!-- Main Sidebar Container -->
  <aside
    class="main-sidebar sidebar-dark-primary elevation-4"
    style="overflow: hidden;"
  >
    <!-- Brand Logo -->
    <a
      href="https://gymbook.in"
      target="_blank"
      class="brand-link"
      style="margin-left: 18px;"
    >
      <span class="brand-text font-weight-light">GymBook (Beta)</span>
    </a>
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="info">
          <a href="#" class="d-block">{{subscriber.userName}}</a>
          <a href="#" class="d-block">{{subscriber.email}}</a>
          <a href="#" class="d-block"
            >Expires on: {{getDDMMMYYYYFormat(subscriber.subscriptionExp)}}</a
          >
        </div>
      </div>
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li
            *ngFor="let nav of navlinks"
            [ngClass]="nav.header? 'nav-header' : 'nav-item'"
          >
            <span *ngIf="nav.header">
              {{nav.title}}
            </span>

            <a [routerLink]="[nav.link]" class="nav-link" *ngIf="!nav.header">
              <i class="nav-icon" [ngClass]="nav.icon"></i>
              <p>
                {{nav.title}}
              </p>
            </a>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <!-- /.content-header -->
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <ng-content></ng-content>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
  <!-- Main Footer -->
  <!-- <footer class="main-footer"></footer> -->
</div>
