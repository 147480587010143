import * as moment from 'moment-timezone'
import { Injectable } from '@angular/core'

const indiaTimezone = 'Asia/Kolkata'

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  static DDMMYYY_FORMAT = 'DD MMM YYYY'
  static FORMAT_YYYY_MM_DD = 'YYYY-MM-DD'
  constructor() { }

  getSystemTimeZone(timezone) {
    if (!timezone) {
      timezone = indiaTimezone
    }
    return timezone
  }

  addTimezone(date, timezone) {
    return moment(date)
      .tz(this.getSystemTimeZone(timezone))
      .format()
  }

  getAppDate(date, timezone) {
    return moment(date || moment().format('MM-DD-YYYY'), 'MM-DD-YYYY')
      .tz(this.getSystemTimeZone(timezone))
      .format(DateTimeService.DDMMYYY_FORMAT)
  }

  getDDMMYYYYFormat(date, timezone) {
    return moment(date)
      .tz(this.getSystemTimeZone(timezone))
      .format('DD-MM-YYYY')
  }

  getDDMMMYYYYFormat(date: string, timezone?: string) {
    return moment(date)
      .tz(this.getSystemTimeZone(timezone))
      .format('DD MMM YYYY')
  }

  convertDateToMilli(date, timezone?) {
    return moment(date)
      .tz(this.getSystemTimeZone(timezone))
      .valueOf()
  }

  getTodayDate(timezone) {
    return moment()
      .tz(this.getSystemTimeZone(timezone))
      .startOf('day')
      .valueOf()
  }

  defaultAppDate(date, timezone) {
    return moment(date)
      .tz(this.getSystemTimeZone(timezone))
      .format('DD MMM YYYY')
  }

  getMomentByYYYYMMDD(date, timezone) {
    return moment(date).valueOf()
  }

  getMoment = (startOfDay = true, unit) => {
    if (startOfDay) {
      return moment().startOf(unit)
    }
    return moment().endOf(unit)
  }

  incrementDate = (base: number, unit: moment.DurationInputArg2, value, startOfDay = true) => {
    if (startOfDay) {
      return moment(base)
        .add(value, unit)
        .startOf(unit)
    }

    return moment(base)
      .add(value, unit)
      .endOf(unit)
  }

  getYYYYMMDD(date, timezone?) {
    return moment(date).format(DateTimeService.FORMAT_YYYY_MM_DD)
  }

  getHumanDate(date, timezone) {
    return moment(date).format('LLL')
  }

  getHumanDateSort(date: string, timezone?: string) {
    return moment(date).format('lll')
  }

  getMilliFromlll(date, timezone) {
    return moment(date, 'lll').valueOf()
  }

  getMMMYYYY(date, timezone) {
    return moment(date).format('MMM YYYY')
  }

  getCurrentMilli = () => {
    return moment().valueOf()
  }

  getStartOfByType = (startOfDay = true, unit, value) => {
    if (startOfDay) {
      return moment(value)
        .startOf(unit)
        .valueOf()
    }
    return moment(value)
      .endOf(unit)
      .valueOf()
  }

  timeDisplay(date, timezone) {
    if (!date) return
    return moment(date).format('hh:mm A')
  }

  /**
   * Get current time in millisecond
   */
  now() {
    return moment.now()
  }
}
