import { environment } from '../../../environments/environment'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { UtilService } from '../util/util.service'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl: string = environment.BASE_API_URL

  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private util: UtilService) { }

  getOptions(url: string, body?: any, qs?: any): any {
    let request: any = { url: this.baseUrl + url, body }
    if (qs) {
      // Initialize Params Object
      let params = new HttpParams();
      for (const key of Object.keys(qs)) {
        if (this.util.isNotNull(qs[key])) {
          params = params.append(key, qs[key]);
        }
      }
      request.params = params
    }

    return request
  }

  formatError(error: HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (error.error && error.error.message) {
        this.toastr.error(error.error.message)
      }
    }
    throw error
  }

  get({ url, params }): Promise<any> {
    return this.http.get(url, { params }).toPromise()
      .catch(this.formatError.bind(this))
  }

  post({ url, params, body }): Promise<any> {
    return this.http.post(url, body, { params: params }).toPromise()
      .catch(this.formatError.bind(this))

  }

  put({ url, params, body }) {
    return this.http.put(url, body, { params }).toPromise()
      .catch(this.formatError.bind(this))

  }

  delete({ url, params }) {
    return this.http.delete(url, { params: params }).toPromise()
      .catch(this.formatError.bind(this))
  }

}
