<div class="container-fluid">
  <h5>{{pageTitle}}</h5>
  <form [formGroup]="batchFormGroup">
    <div class="m-1rm align-center">
      <mat-form-field>
        <mat-label>Batch name</mat-label>
        <input
          matInput
          placeholder="Batch Name"
          formControlName="name"
          required
        />
      </mat-form-field>
      <br />

      <mat-form-field>
        <mat-label>Batch Limit</mat-label>
        <input
          matInput
          placeholder="Batch Limit"
          formControlName="batchLimit"
          required
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Batch Open Time</mat-label>
        <input
          matInput
          [ngxTimepicker]="startTime"
          [format]="12"
          placeholder="Batch Open Time"
          formControlName="startTime"
          required
        />
      </mat-form-field>
      <ngx-material-timepicker #startTime></ngx-material-timepicker>

      <mat-form-field>
        <mat-label>Batch Close Time</mat-label>
        <input
          matInput
          [ngxTimepicker]="endTime"
          [format]="12"
          placeholder="Batch Close Time"
          formControlName="endTime"
          required
        />
      </mat-form-field>
      <ngx-material-timepicker #endTime></ngx-material-timepicker>

      <br />
      <br />
      <br />
    </div>
  </form>

  <div class="col-12">
    <button
      mat-raised-button
      color="primary"
      mat-dialog-close
      (click)="closeDialog()"
    >
      Cancel
    </button>
    &nbsp; &nbsp;
    <button
      mat-raised-button
      color="primary"
      type="submit"
      (click)="onSubmit(batchFormGroup.value)"
      cdkFocusInitial
    >
      Save
    </button>
  </div>
</div>
