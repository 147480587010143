import * as _ from 'lodash'
import { ActivatedRoute, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { MemberService } from '../member.service'
import { PlanService } from '../../plan/plan.service'
import { UtilService } from 'src/app/service/util/util.service'
@Component({
  selector: 'app-members-context',
  templateUrl: './members-context.component.html',
  styleUrls: ['./members-context.component.scss']
})
export class MembersContextComponent implements OnInit {

  name: string
  context: string

  members = []
  plans = []
  // Member filter
  searchText: string = null
  selectedPlanFilter: string = 'null'

  constructor(private _activatedroute: ActivatedRoute,
    private memberService: MemberService,
    private planService: PlanService,
    private router: Router,
    private utilService: UtilService) {

    this.context = this._activatedroute.snapshot.paramMap.get("context");
    this.name = this._activatedroute.snapshot.queryParamMap.get('name')
    this.getAllMembersContext()
    this.getAllPlan()
  }

  ngOnInit(): void {
  }

  async getAllPlan() {
    try {
      let plans = await this.planService.getAllPackage()
      this.plans = plans.data
    } catch (error) {

    }
  }


  async getAllMembersContext(fromStart: boolean = true) {
    try {
      if (fromStart) {
        // Fetch the data from start
        let members = await this.memberService.getAllMembersContext(
          0,
          this.searchText,
          this.context,
          this.selectedPlanFilter
        )
        this.members = members.data
      } else {
        // Fetch the data from middle
        let members = await this.memberService.getAllMembersContext(
          this.members.length,
          this.searchText,
          this.context,
          this.selectedPlanFilter)
        this.members = _.unionBy(this.members, members.data, '_id')
      }

    } catch (error) {

    }
  }

  onScroll() {
    this.getAllMembersContext(false)
  }

  changeMemberFilter() {
    this.getAllMembersContext()
  }


  /**
   * View Member Detail Screen
   * @param member 
   */
  viewMember(member: any) {
    this.router.navigate(['/member/' + member._id]);

  }

  /**
   * Delete Member
   */
  async deleteMember(member: any) {
    try {
      let memberId = member._id
      await this.memberService.deleteMemberApi(memberId)
      let deletedMemberIndex = this.members.findIndex(member => member._id === memberId)
      if (this.utilService.isFillArray(this.members) && deletedMemberIndex >= 0) {
        this.members.splice(deletedMemberIndex, 1)
      }
    } catch (error) {

    }

  }
}
