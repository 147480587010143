<div class="small-box" [class]="titleClass">
  <div class="inner">
    <h3>{{count}}</h3>

    <p>{{title}}</p>
  </div>
  <div class="icon">
    <i class="ion" [class]="descIconClass"></i>
  </div>
  <a class="small-box-footer"
    >More info <i class="fas fa-arrow-circle-right"></i
  ></a>
</div>
