<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">{{name}}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">{{name}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-2 col-xl-2 col-sm-4 col-md-4 col-xs-4">
    <mat-form-field>
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput
        type="search"
        placeholder="Search Member Name/ Mobile"
        class="search-input"
        [(ngModel)]="searchText"
        (ngModelChange)="changeMemberFilter()"
      />
    </mat-form-field>
  </div>
  <div class="col-lg-2 col-xl-2 col-sm-4 col-md-4 col-xs-4">
    <mat-form-field>
      <mat-label>Gym Plans</mat-label>
      <mat-select
        [(ngModel)]="selectedPlanFilter"
        (ngModelChange)="changeMemberFilter()"
      >
        <mat-option value="null">All</mat-option>
        <mat-option [value]="plan.name" *ngFor="let plan of plans"
          >{{plan.name}}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div
  class="content"
  infiniteScroll
  [infiniteScrollDistance]="3"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <div class="row">
    <div
      class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 d-flex align-items-stretch"
      *ngFor="let member of members"
      app-membercard
      [member]="member"
      (viewMember)="viewMember($event)"
      (deleteMember)="deleteMember($event)"
    ></div>
  </div>
</div>
