<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Member Detail</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item">
            <a
              [routerLink]="[LIST_MEMBER]"
              routerLinkActive="router-link-active"
              >Member</a
            >
          </li>
          <li class="breadcrumb-item active">Member Detail</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
        <mat-card>
          <mat-card-content>
            <div class="text-center">
              <div style="height: 128px; width: 128px; display: inline-block">
                <img
                  [src]="memberDetail.photo"
                  style="height: 100%;width: 100%;"
                  class="img-circle img-fluid"
                />
              </div>
            </div>
            <div>
              <app-split-text
                firstLabel="Name"
                [firstText]="memberDetail.name"
                secondLabel="Mobile"
                [secondText]="memberDetail.mobile"
              >
              </app-split-text>

              <app-split-text
                firstLabel="Training Type"
                [firstText]="memberDetail.trainingType"
                secondLabel="Gender"
                [secondText]="memberDetail.gender"
              >
              </app-split-text>

              <app-split-text
                firstLabel="DOB"
                [firstText]="getDDMMMYYYYFormat(memberDetail.dob)"
                secondLabel="Email"
                [secondText]="memberDetail.email"
              >
              </app-split-text>

              <app-split-text
                firstLabel="Address"
                [firstText]="memberDetail.address"
                secondLabel="Notes"
                [secondText]="memberDetail.notes"
              >
              </app-split-text>

              <app-split-text
                firstLabel="Membership Id"
                [firstText]="memberDetail.membershipId"
              >
              </app-split-text>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <div class="text-right">
              <button
                mat-button
                color="primary"
                (click)="editMember(memberDetail)"
              >
                <mat-icon>create</mat-icon>
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>

      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
        <mat-card>
          <mat-card-content>
            <div class="title-spacing">
              <div>
                Plans
              </div>
              <div>
                <a [routerLink]="" (click)="btnViewAllPlan(memberDetail._id)"
                  >View All Plan</a
                >
                &nbsp;&nbsp;
                <a
                  (click)="btnRenewOrEditPlan(memberDetail._id, null, false)"
                  [routerLink]=""
                  >Renew Plan</a
                >
              </div>
            </div>
            <div *ngFor="let package of packages">
              <div style="display: inline-block; margin-bottom: 0.2rem;">
                <mat-divider></mat-divider>
              </div>
              <app-split-text
                firstLabel="Plans Name"
                [firstText]="package.name"
                secondLabel="Total Amount"
                [secondText]="package.totalAmount"
                editIcon="edit"
                (btnEditClick)="btnRenewOrEditPlan(memberDetail._id,package, true)"
              >
              </app-split-text>

              <app-split-text
                firstLabel="Paid"
                [firstText]="package.paid"
                secondLabel="Discount"
                [secondText]="package.discount"
                deleteIcon="delete"
                (btnDeleteClick)="btnDeletePlan(memberDetail._id,package._id)"
              >
              </app-split-text>

              <app-split-text
                firstLabel="Purchase Date"
                [firstText]="getDDMMMYYYYFormat(package.purchaseDate)"
              >
              </app-split-text>

              <app-split-text
                firstLabel="Expiry Date"
                [firstText]="getDDMMMYYYYFormat(package.expiryDate)"
                secondLabel="Due Amount"
                [secondText]="package.pendingAmount"
              >
              </app-split-text>
              <div>
                Comment:
                <div *ngFor="let comment of package.comments">
                  <div>{{comment.text}}</div>
                  <div>{{getHumanDateSort(comment.createdAt)}}</div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 card-gutter">
        <mat-card>
          <mat-card-content>
            <div class="title-spacing">
              <div>
                Batch
              </div>
              <div>
                <a
                  *ngIf="!batch.name"
                  (click)="editMember(memberDetail)"
                  [routerLink]=""
                  >Add batch</a
                >
              </div>
            </div>

            <mat-divider></mat-divider>
            <div *ngIf="batch.name">
              <app-split-text
                firstLabel="Batch Name"
                [firstText]="batch.name"
                secondLabel="Batch Limit"
                [secondText]="batch.batchLimit"
                editIcon="edit"
                (btnEditClick)="editMember(memberDetail)"
              >
              </app-split-text>

              <app-split-text
                firstLabel="Available Limit"
                [firstText]="batch.batchLimit - batch.currentMember"
                secondLabel="Batch Time"
                [secondText]="batch.startTime"
              >
              </app-split-text>

              <app-split-text
                firstLabel="Total Members In Batch"
                [firstText]="batch.currentMember"
              >
              </app-split-text>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 card-gutter">
        <mat-card>
          <mat-card-content>
            <div class="title-spacing">
              <div>
                Services
              </div>
              <div>
                <a href="#">View All Services</a>
                &nbsp;&nbsp;
                <a href="#">Add Service</a>
              </div>
            </div>
            <mat-divider></mat-divider>
          </mat-card-content>
        </mat-card>
      </div> -->
    </div>
  </div>
</section>
