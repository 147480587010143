import { AuthService } from 'src/app/auth/auth.service'
import { Component, OnInit } from '@angular/core'
import { DashboardService } from 'src/app/service/api/dashboard.service'
import { DateTimeService } from 'src/app/service/util/date-time.service'
import { FirebaseAnalyticsService } from 'src/app/service/util/firebase-analytics.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  membersDetailsGrid = []
  memberExpireyGrid = []
  memberTodayEvent = []

  constructor(private dashboard: DashboardService,
    private authService: AuthService,
    private dateTimeUtil: DateTimeService,
    private firebaseAnalytic: FirebaseAnalyticsService,
    private router: Router) { }


  ngOnInit(): void {
    this.getDashboardStats()
    this.exchangeToken()
    this.firebaseAnalytic.logEvent(FirebaseAnalyticsService.DASHBOARD_PAGE)
  }

  /**
   * Exchange token after 5 days
   */
  async exchangeToken() {
    try {
      let time = this.authService.getAppLoginTime()
      if (time && time > 0) {
        let after4Days = this.dateTimeUtil.incrementDate(time, 'day', 4, true).valueOf()
        if (this.dateTimeUtil.now() > after4Days) {
          let response = await this.dashboard.exchangeToken()
          let token = response.data.authToken
          this.authService.setToken(token)
          this.authService.setAppLoginTime()
        }
      } else {
        this.authService.setAppLoginTime()
      }
    } catch (error) {

    }
  }


  async getDashboardStats() {

    try {
      let stats = await this.dashboard.getDashboardStats()
      const {
        memberWhoseMembershipExpire,
        membershipExpireInOneToThreeDays,
        membershipExpireInFourtoSevenDays,
        membershipExpireInSevenToFifteenDays,
        memberWhoseMembershipIsActive,
        totalRegUser,
        getBlockUser,
        membershipExpireToday,
        memberTodayBirthday
      } = stats.data

      this.membersDetailsGrid = [
        {
          name: 'Total Members',
          count: totalRegUser,
          type: 'totalRegUser',
          className: 'bg-success'
        },
        {
          name: 'Active Members',
          count: memberWhoseMembershipIsActive,
          type: 'memberWhoseMembershipIsActive',
          className: 'bg-success'
        },
        {
          name: 'Expired Members',
          count: memberWhoseMembershipExpire,
          type: 'memberWhoseMembershipExpire',
          className: 'bg-danger'
        },
        {
          name: 'Block Members',
          count: getBlockUser,
          type: 'getBlockUser',
          className: 'bg-warning'
        }
      ]

      this.memberExpireyGrid = [
        {
          name: 'Expiry in next (1-3) days',
          count: membershipExpireInOneToThreeDays,
          type: 'membershipExpireInOneToThreeDays',
          className: 'bg-info'
        },
        {
          name: 'Expiry in next (4-7) days',
          count: membershipExpireInFourtoSevenDays,
          type: 'membershipExpireInFourtoSevenDays',
          className: 'bg-info'
        },
        {
          name: 'Expiry in next (7-15) days',
          count: membershipExpireInSevenToFifteenDays,
          type: 'membershipExpireInSevenToFifteenDays',
          className: 'bg-info'
        }
      ]

      this.memberTodayEvent = [
        {
          name: 'Today Birthday',
          count: memberTodayBirthday,
          type: 'memberTodayBirthday',
          className: 'bg-success'
        },
        {
          name: 'Membership Expiry Today',
          count: membershipExpireToday,
          type: 'membershipExpireToday',
          className: 'bg-danger'
        }
      ]
    } catch (error) {
      console.log('error', error)

    }
  }

  /**
   * Open member context page
   * @param memberContext 
   */
  btnMemberContextDetail(memberContext) {
    this.router.navigate(['/members/' + memberContext.type], { queryParams: { name: memberContext.name } });
  }


}
