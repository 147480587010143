import Constant from 'src/constant'
import { Component, OnInit } from '@angular/core'
import { MemberService } from '../members/member.service'

@Component({
  selector: 'app-download-member',
  templateUrl: './download-member.component.html',
  styleUrls: ['./download-member.component.scss']
})
export class DownloadMemberComponent implements OnInit {

  constructor(private memberService: MemberService) { }
  MEMBER_EXCEL_DOWNLOAD_FILTER = Constant.MEMBER_EXCEL_DOWNLOAD_FILTER
  selectedReport: any = this.MEMBER_EXCEL_DOWNLOAD_FILTER[0]

  ngOnInit(): void {
  }

  /**
   * Download Report from server
   */
  async downloadReport() {
    try {
      let { data } = await this.memberService.downloadMember(this.selectedReport.context)
      let { fileName } = data
      window.open(fileName, "_blank");
    } catch (error) {

    }
  }

}
