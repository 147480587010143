export default class Constant {
    static ROUTERS = {
        LIST_MEMBER: '/members',
        VISITOR: '/visitor',
        EXPENSE: '/expense',
        DASHBOARD: '/dashboard',
        MANAGESTAFF: '/managestaff',
        SUBSCRIPTION: '/subscription',
        DOWNLOAD_MEMBER: '/member-download',
        GYM_PLAN: '/gym-plan',
        GYM_SERVICE: '/gym-service',
        GYM_WHATSUP_TEMPLATE: '/whatsup-template',
        GYM_BATCH: '/batch',
        MEMBER: '/member',
        ADD_MEMBER: '/add-member',
        COLLECTION: '/collection'
    }

    static PLAN_MONTHS = [
        {
            label: '1 Month',
            value: 1
        },
        {
            label: '2 Month',
            value: 2
        },
        {
            label: '3 Month',
            value: 3
        },
        {
            label: '4 Month',
            value: 4
        },
        {
            label: '5 Month',
            value: 5
        },
        {
            label: '6 Month',
            value: 6
        },
        {
            label: '7 Month',
            value: 7
        },
        {
            label: '8 Month',
            value: 8
        },
        {
            label: '9 Month',
            value: 9
        },
        {
            label: '10 Month',
            value: 10
        },
        {
            label: '11 Month',
            value: 11
        },
        {
            label: '12 Month',
            value: 12
        },
        {
            label: '13 Month',
            value: 13
        },
        {
            label: '14 Month',
            value: 14
        },
        {
            label: '15 Month',
            value: 15
        },
        {
            label: '16 Month',
            value: 16
        },
        {
            label: '17 Month',
            value: 17
        },
        {
            label: '18 Month',
            value: 18
        },
        {
            label: '19 Month',
            value: 19
        },
        {
            label: '20 Month',
            value: 20
        },
        {
            label: '21 Month',
            value: 21
        },
        {
            label: '22 Month',
            value: 22
        },
        {
            label: '23 Month',
            value: 23
        },
        {
            label: '24 Month',
            value: 24
        }
    ]

    static PLAN_TYPES = {
        PLAN: 'management',
        SMS_BUY: 'sms_plan'
    }

    static PAGINATION_LIMIT = 40

    static MEMBER_FILTER = {
        ALL: 'all',
        IN_ACTIVE_MEMBER: 'inactive',
        ACTIVE_MEMBER: 'active'
    }

    static TRAINING_TYPE = {
        GENERAL_TRAINING: 'General training',
        PERSONAL_TRAINING: 'Personal training'
    }

    static DISCOUNT_TYPE = {
        PERCENT: 'Percent',
        AMOUNT: 'Amount'
    }

    static PLAN_DISCOUNT = ['percent', 'amount']

    static MEMBER_EXCEL_DOWNLOAD_FILTER = [
        {
            title: 'All Members',
            context: 'totalRegUser'
        },
        {
            title: 'Active Members',
            context: 'memberWhoseMembershipIsActive'
        },
        {
            title: 'In Active Members',
            context: 'memberWhoseMembershipExpire'
        },
        {
            title: 'Expiry in next(1-3) days',
            context: 'membershipExpireInOneToThreeDays'
        },
        {
            title: 'Expiry in next(4-7) days ',
            context: 'membershipExpireInFourtoSevenDays'
        },
        {
            title: 'Expiry in next(7-15) days',
            context: 'membershipExpireInSevenToFifteenDays'
        },
        {
            title: 'Partial Paid Members',
            context: 'partialPaidPackage'
        },
        {
            title: 'Unpaid Members',
            context: 'unpaidPackage'
        },
        {
            title: 'Block Members',
            context: 'getBlockUser'
        }
    ]

}

export {
    Constant
}