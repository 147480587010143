import Response from 'src/app/service/api/response-parser'
import { Component, OnInit } from '@angular/core'
import { GymPlanDialogComponent } from './gym-plan-dialog/gym-plan-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { PlanService } from './plan.service'

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  constructor(
    private planService: PlanService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getAllPackage()
  }

  plans = []

  async getAllPackage() {
    try {
      let packages = await this.planService.getAllPackage()
      this.plans = Response.parseGymPlan(packages.data)
    } catch (error) {

    }
  }

  async deleteCard(plan) {
    try {
      await this.planService.deletePlan(plan)
      await this.getAllPackage()
    } catch (error) {

    }
  }

  editCard(card) {
    const dialogRef = this.dialog.open(GymPlanDialogComponent, {
      data: {
        ui: 'EditPlan',
        plan: card
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.getAllPackage()
    })
  }

  /**
   * Add Plan for gym
   */
  addPlan() {
    const dialogRef = this.dialog.open(GymPlanDialogComponent, {
      data: {
        ui: 'AddPlan'
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.getAllPackage()
    })
  }

}
