import { Component } from '@angular/core'
import { FirebaseAnalyticsService } from './service/util/firebase-analytics.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gymbook';
  constructor(public firebaseAnalyticSer: FirebaseAnalyticsService) {
    this.firebaseAnalyticSer.initialize()
  }
}
