import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
  } from '@angular/core'
import { DateTimeService } from 'src/app/service/util/date-time.service'
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: '[app-membercard]',
  templateUrl: './membercard.component.html',
  styleUrls: ['./membercard.component.scss']
})
export class MembercardComponent implements OnInit {

  @Input() member;
  @Output() viewMember = new EventEmitter<any>()
  @Output() deleteMember = new EventEmitter<any>()
  constructor(private dateTimeService: DateTimeService,
    private dialog: MatDialog) { }

  ngOnInit(): void {

  }

  viewMemberDetail(member: any) {
    this.viewMember.emit(member)
  }

  getDDMMMYYYYFormat(date: string) {
    if (date) {
      return this.dateTimeService.getDDMMMYYYYFormat(date)
    }
  }

  deleteMemberDialog(member: any) {

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
    })
    dialogRef.afterClosed()
      .subscribe((result) => {
        if (result) {
          this.deleteMember.emit(member)
        }
      })
  }

}
