<app-layout-header>
  <h1>Members</h1>
  <div>
    <a
      [routerLink]="[ADD_MEMBER_URL]"
      routerLinkActive="router-link-active"
      class="btn btn-sm btn-success"
    >
      &nbsp;
      <i class="fas fa-user-plus"></i>
      &nbsp; Add Member
    </a>
  </div>
</app-layout-header>

<div class="row">
  <div class="col-lg-2 col-xl-2 col-sm-4 col-md-4 col-xs-4">
    <mat-form-field>
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput
        type="search"
        placeholder="Search Member Name/ Mobile"
        class="search-input"
        [(ngModel)]="searchText"
        (ngModelChange)="changeMemberFilter()"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-12 col-xs-12 col-lg-6 col-xl-6 col-md-6">
    <div style="display: flex;">
      <div style="width: 25%;">
        <mat-form-field>
          <mat-label>Member Type</mat-label>
          <mat-select
            [(ngModel)]="selectedMemberFilter"
            (ngModelChange)="changeMemberFilter()"
          >
            <mat-option value="all">All</mat-option>
            <mat-option value="active">Active</mat-option>
            <mat-option value="inactive">In Active</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <div style="width: 25%;">
        <mat-form-field>
          <mat-label>Gym Plans</mat-label>
          <mat-select
            [(ngModel)]="selectedPlanFilter"
            (ngModelChange)="changeMemberFilter()"
          >
            <mat-option value="null">All</mat-option>
            <mat-option [value]="plan.name" *ngFor="let plan of plans"
              >{{plan.name}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <div style="width: 25%;">
        <mat-form-field>
          <mat-label>Training Type</mat-label>
          <mat-select
            [(ngModel)]="selectedTraining"
            (ngModelChange)="changeMemberFilter()"
          >
            <mat-option value="null">All</mat-option>
            <mat-option value="General training">General Training</mat-option>
            <mat-option value="Personal training">Personal Training</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <div style="width: 25%;">
        <mat-form-field>
          <mat-label> Batch</mat-label>
          <mat-select
            [(ngModel)]="selectedBatchFilter"
            (ngModelChange)="changeMemberFilter()"
          >
            <mat-option value="null">All</mat-option>
            <mat-option [value]="batch._id" *ngFor="let batch of batches"
              >{{batch.name}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

<div
  class="content"
  infiniteScroll
  [infiniteScrollDistance]="3"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <div class="row">
    <div
      class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 d-flex align-items-stretch"
      *ngFor="let member of members"
      app-membercard
      [member]="member"
      (viewMember)="viewMember($event)"
      (deleteMember)="deleteMember($event)"
    ></div>
  </div>
</div>
