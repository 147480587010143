import { ApiService } from '../../service/api/api.service'
import { Constant } from '../../../constant'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(private api: ApiService) { }
  getAllMembers(startIndex: number = 0,
    searchText: string = null,
    context: string = null,
    selectedTraining: string = null,
    selectedPlanFilter: string = null,
    selectedBatchFilter: string = null) {
    let endIndex = startIndex + Constant.PAGINATION_LIMIT
    let options = this.api.getOptions(`/member/${startIndex}/${endIndex}`,
      null, {
      q: searchText,
      memberContext: context,
      selectedTraining: selectedTraining,
      trainingType: selectedTraining,
      packageName: selectedPlanFilter,
      batchId: selectedBatchFilter
    })
    return this.api.get(options);
  }

  getAllMembersContext(
    startIndex: number = 0,
    searchText: string = null,
    context: string = null,
    selectedPlanFilter: string = null
  ) {

    let endIndex = startIndex + Constant.PAGINATION_LIMIT
    let options = this.api.getOptions(`/member/dashboard/member`,
      null, {
      context: context,
      startIndex: startIndex,
      endIndex: endIndex,
      packageName: selectedPlanFilter,
      q: searchText,
      startDate: null,
      endDate: null,
      apiVersion: 1
    })
    return this.api.get(options);
  }

  getMemberDetailForPreReq() {
    let options = this.api.getOptions('/member/prerequisite')
    return this.api.get(options);
  }

  addMemberAPI(input: any): Promise<any> {
    let options = this.api.getOptions('/member', input)
    return this.api.post(options);
  }

  getMemberDetail(memberId: string) {
    let options = this.api.getOptions('/member/' + memberId)
    return this.api.get(options);
  }

  /**
   * Delete Gym Member
   * @param memberId 
   */
  deleteMemberApi(memberId: string): Promise<any> {
    let options = this.api.getOptions('/member/' + memberId)
    return this.api.delete(options)
  }

  downloadMember(context: string) {
    let options = this.api.getOptions('/member/download/excel', null, { fileContext: context })
    return this.api.get(options)
  }

  updateProfile(profile: any): Promise<any> {
    let options = this.api.getOptions(`/member/${profile._id}`, profile)
    return this.api.put(options)
  }

  // Plan api
  addPlan(memberID, packageDetail) {
    let options = this.api.getOptions(`/member/${memberID}/package`, packageDetail)
    return this.api.post(options)
  }

  editPlan(packageID, packageDetail) {
    let options = this.api.getOptions(`/member/package/${packageID}`, packageDetail)
    return this.api.put(options)
  }

  deletePlan(memberID, packageID) {
    let options = this.api.getOptions(`/member/${memberID}/package/${packageID}`)
    return this.api.delete(options)
  }

  getPlanByMemberId(memberID: string) {
    let options = this.api.getOptions(`/member/${memberID}/packages`)
    return this.api.get(options)
  }

}
