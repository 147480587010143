import { CookiesService } from '../service/cookies/cookies.service'
import { DateTimeService } from '../service/util/date-time.service'
import { Injectable } from '@angular/core'
import { UtilService } from '../service/util/util.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private USER = 'USER'
  private APP_LOGIN_TIME = 'APP_LOGIN_TIME'

  constructor(private cookies: CookiesService,
    private util: UtilService,
    private dateTimeService: DateTimeService) { }
  isLogin() {
    return this.util.isNotNull(this.cookies.get(this.USER))
  }

  getToken() {
    return this.cookies.get(this.USER)
  }

  /**
   * Return time in millisecond
   */
  getAppLoginTime() {
    let time = this.cookies.get(this.APP_LOGIN_TIME)
    if (time && typeof time === 'string' && time.trim().length > 1) {
      return parseInt(time)
    }
    return null
  }

  /**
   * Set app login time
   * @param time 
   */
  setAppLoginTime(time: number = this.dateTimeService.now()) {
    if (time && time >= 0) {
      return this.cookies.set(this.APP_LOGIN_TIME, time.toString())
    }
  }

  /**
   * Set token
   * @param token 
   */
  setToken(token: string) {
    return this.cookies.set(this.USER, token)
  }
}
