import { AddmemberComponent } from './component/members/addmember/addmember.component'
import { AuthGuard } from './auth/auth.guard'
import { BatchComponent } from './component/batch/batch.component'
import { CollectionComponent } from './component/collection/collection.component'
import { DashboardComponent } from './component/dashboard/dashboard.component'
import { DownloadMemberComponent } from './component/download-member/download-member.component'
import { EditMemberComponent } from './component/members/member-detail/edit-member/edit-member.component'
import { ExpenseComponent } from './component/expense/expense.component'
import { ManagestaffComponent } from './component/managestaff/managestaff.component'
import { MemberdetailComponent } from './component/members/member-detail/member-detail.component'
import { MemberPlanComponent } from './component/members/member-detail/member-plan/member-plan.component'
import { MembersComponent } from './component/members/members.component'
import { MembersContextComponent } from './component/members/members-context/members-context.component'
import { NgModule } from '@angular/core'
import { PlanComponent } from './component/plan/plan.component'
import { RouterModule, Routes } from '@angular/router'
import { ServiceComponent } from './component/service/service.component'
import { SubscriptionComponent } from './component/subscription/subscription.component'
import { VisitorComponent } from './component/visitor/visitor.component'
import { WhatsuptemplateComponent } from './component/whatsuptemplate/whatsuptemplate.component'

const routes: Routes = [
  {
    path: 'member/:memberId', component: MemberdetailComponent, canActivate: [AuthGuard]
  },
  {
    path: 'member/:memberId/edit', component: EditMemberComponent, canActivate: [AuthGuard]
  },
  {
    path: 'member/:memberId/plan', component: MemberPlanComponent, canActivate: [AuthGuard]
  },
  {
    path: 'members', component: MembersComponent, canActivate: [AuthGuard]
  },
  {
    path: 'members/:context', component: MembersContextComponent, canActivate: [AuthGuard]
  },
  {
    path: 'add-member', component: AddmemberComponent, canActivate: [AuthGuard]
  },
  {
    path: 'visitor', component: VisitorComponent, canActivate: [AuthGuard]
  },
  {
    path: 'expense', component: ExpenseComponent, canActivate: [AuthGuard]
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]
  },
  {
    path: 'managestaff', component: ManagestaffComponent, canActivate: [AuthGuard]
  },
  {
    path: 'subscription', component: SubscriptionComponent, canActivate: [AuthGuard]
  },
  {
    path: 'member-download', component: DownloadMemberComponent, canActivate: [AuthGuard]
  },
  {
    path: 'gym-plan', component: PlanComponent, canActivate: [AuthGuard]
  },
  {
    path: 'gym-service', component: ServiceComponent, canActivate: [AuthGuard]
  },
  {
    path: 'whatsup-template', component: WhatsuptemplateComponent, canActivate: [AuthGuard]
  },
  {
    path: 'batch', component: BatchComponent, canActivate: [AuthGuard]
  },
  {
    path: 'collection', component: CollectionComponent, canActivate: [AuthGuard]
  },
  {
    path: '', component: DashboardComponent, canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
