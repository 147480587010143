<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Subscription</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Subscription</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <ng-container *ngFor="let plan of plans">
      <div class="card col-lg-4  col-12">
        <div class="card-header">
          <h3 class="card-title">{{plan.title}}</h3>
        </div>
        <div class="card-body">
          <div *ngFor="let desc of plan.description">
            {{desc}}
          </div>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-block btn-success"
            (click)="buyNowClick(plan.type)"
          >
            {{plan.btnText}}
          </button>
        </div>
      </div>
      <div class="col-lg-1"></div>
    </ng-container>
  </div>
</div>
