<div class="card bg-light" style="width: 90%;">
  <div class="card-body">
    <div class="row">
      <div class="col-7">
        <app-split-text
          firstLabel="Name"
          [firstText]="member.name"
          secondLabel="Mobile"
          [secondText]="member.mobile"
        >
        </app-split-text>

        <app-split-text
          firstLabel="Plan Expiry"
          [firstText]="getDDMMMYYYYFormat(member.membershipExpiryDate)"
          secondLabel="Due Amount"
          [secondText]="member.pendingAmount"
        >
        </app-split-text>

        <app-split-text
          firstLabel="Membership Id"
          [firstText]="member.membershipId"
        >
        </app-split-text>
      </div>
      <div class="col-5 text-center">
        <div style="height: 128px; width: 128px;">
          <img
            [src]="member.photo"
            style="height: 100%;width: 100%;"
            alt=""
            class="img-circle img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="text-right">
      <button class="btn btn-sm bg-danger" (click)="deleteMemberDialog(member)">
        <i class="fas fa-trash"></i>
      </button>
      &nbsp;
      <button class="btn btn-sm btn-primary" (click)="viewMemberDetail(member)">
        <i class="fas fa-file-contract"></i> &nbsp; View
      </button>
    </div>
  </div>
</div>
