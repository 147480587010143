import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-managestaff',
  templateUrl: './managestaff.component.html',
  styleUrls: ['./managestaff.component.scss']
})
export class ManagestaffComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
