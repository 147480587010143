import { ApiService } from 'src/app/service/api/api.service'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class BatchService {

  constructor(private api: ApiService) { }

  addBatch(body) {
    let url = this.api.getOptions('/batch', body)
    return this.api.post(url)
  }

  getBatch() {
    let url = this.api.getOptions('/batch')
    return this.api.get(url)
  }

  deleteBatch(batch) {
    let url = this.api.getOptions('/batch/' + batch._id)
    return this.api.delete(url)
  }

  editBatch(batch) {
    let url = this.api.getOptions('/batch/' + batch._id, batch)
    return this.api.put(url)
  }

}
