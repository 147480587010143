import firebase from 'firebase/app'
import { environment } from '../../../environments/environment'
import { Injectable } from '@angular/core'
import { UtilService } from './util.service'
import 'firebase/analytics'

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {

  //Event
  static DASHBOARD_PAGE = 'DashboardPage'


  analytics
  constructor(private util: UtilService) {

  }

  // Initialize Firebase
  initialize() {
    firebase.initializeApp(environment.firebaseConfig);
    this.analytics = firebase.analytics()
  }

  /**
   * Log firebase event
   * @param eventName 
   * @param proper 
   */
  logEvent(eventName: string, props?: any) {
    if (this.util.isNotNull(props)) {
      this.analytics.logEvent(eventName, props)
    } else {
      this.analytics.logEvent(eventName)
    }
  }

  /**
   * 
   * @param userId 
   * @param props 
   */
  addUserToFirebase(userId: string, props?: any) {
    firebase.analytics().setUserId(userId)
    if (this.util.isNotNull(props)) {
      firebase.analytics().setUserProperties(props)
    }
  }
}
